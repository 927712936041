import React from 'react';
import PropTypes from 'prop-types'
import yup from 'yup';

class IndividualInsuranceForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: {}
		};

		this.validate = this.validate.bind(this);
	}

	validate() {
		const schema = yup.object().shape({
			firstNameForm: yup.string().required("Please fill in this section"),
			lastNameForm: yup.string().required("Please fill in this section"),
			address1Form: yup.string().required("Please fill in this section"),
			zipCodeForm: yup.string().required("Please fill in this section"),
			cityForm: yup.string().required("Please fill in this section"),
			phoneNumberForm: yup.string()
				.matches(/\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, "Please enter a valid phone number")
				.required("Please enter a valid phone number"),
			birthdayForm: yup.string()
					.matches(/(1[0-9]{3}|2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/, "Please enter a valid date")
					.required("Please enter a valid date")
		});

		const context = this;
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				context.props.nextStep();
			})
			.catch((err) => {
				// errors
				context.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
			})
	}

	render() {
		return (
			<div>
				<p>
					Great! We'll just need to confirm some details to instantly process your insurance policy.
				</p>
				<div className="sk-form__wrapper">
					<div className="sk-form__row">
						<label htmlFor="accoutType" className="sk-form__input-wrapper--third">
							<span className="sk-form__input-label--size-lg">
								I am a...
							</span>
							<div className="sk-form__select-wrapper">
								<select name="accountTypeForm" className="sk-form__select account-type-select" value={this.props.accountTypeForm} onChange={this.props.handleFormChange}>
									<option value="sole_proprietorship">Sole Proprietorship</option>
									<option value="general_partnership">General Partnership</option>
									<option value="limited_partnership">Limited Partnership</option>
									<option value="limited_liability_partnership">Limited Liability Partnership (LLP)</option>
									<option value="corporation">Corporation</option>
									<option value="nonprofit">Nonprofit</option>
									<option value="limited_liability_company">Limited Liability Company (LLC)</option>
									<option value="trust">Trust</option>
									<option value="joint_venture">Joint Venture (JV)</option>
								</select>
							</div>
						</label>
						<label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            Legal First Name
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="firstNameForm" value={this.props.firstNameForm} onChange={this.props.handleFormChange} />
							{this.state.errors.firstNameForm && <span className="concierge-warning">{this.state.errors.firstNameForm}</span>}
		        </label>
		        <label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            Legal Last Name
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="lastNameForm" value={this.props.lastNameForm} onChange={this.props.handleFormChange} />
							{this.state.errors.lastNameForm && <span className="concierge-warning">{this.state.errors.lastNameForm}</span>}
		        </label>
					</div>

	        <div className="sk-form__row">
		        <label className="sk-form__input-wrapper--half">
		          <span className="sk-form__input-label">
		            Street address
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="address1Form" value={this.props.address1Form} onChange={this.props.handleFormChange} />
							{this.state.errors.address1Form && <span className="concierge-warning">{this.state.errors.address1Form}</span>}
		        </label>
		        <label className="sk-form__input-wrapper--quarter">
		          <span className="sk-form__input-label">
		            Unit
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="address2Form" value={this.props.address2Form} onChange={this.props.handleFormChange} />
							{this.state.errors.address2Form && <span className="concierge-warning">{this.state.errors.address2Form}</span>}
		        </label>
	        </div>
	        <div className="sk-form__row">
		        <label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            Zip code
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="zipCodeForm" value={this.props.zipCodeForm} onChange={this.props.handleFormChange} />
							{this.state.errors.zipCodeForm && <span className="concierge-warning">{this.state.errors.zipCodeForm}</span>}
		        </label>
		        <label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            City
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="cityForm" value={this.props.cityForm} onChange={this.props.handleFormChange} />
							{this.state.errors.cityForm && <span className="concierge-warning">{this.state.errors.cityForm}</span>}
		        </label>
		        <label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            State
		          </span>
							<select name="stateForm" className="sk-form__select project-select" value={this.props.stateForm} onChange={this.props.handleFormChange}>
							  <option value="" defaultValue="selected">Select a State</option>
							  <option value="AL">Alabama</option>
							  <option value="AK">Alaska</option>
							  <option value="AZ">Arizona</option>
							  <option value="AR">Arkansas</option>
							  <option value="CA">California</option>
							  <option value="CO">Colorado</option>
							  <option value="CT">Connecticut</option>
							  <option value="DE">Delaware</option>
							  <option value="DC">District Of Columbia</option>
							  <option value="FL">Florida</option>
							  <option value="GA">Georgia</option>
							  <option value="HI">Hawaii</option>
							  <option value="ID">Idaho</option>
							  <option value="IL">Illinois</option>
							  <option value="IN">Indiana</option>
							  <option value="IA">Iowa</option>
							  <option value="KS">Kansas</option>
							  <option value="KY">Kentucky</option>
							  <option value="LA">Louisiana</option>
							  <option value="ME">Maine</option>
							  <option value="MD">Maryland</option>
							  <option value="MA">Massachusetts</option>
							  <option value="MI">Michigan</option>
							  <option value="MN">Minnesota</option>
							  <option value="MS">Mississippi</option>
							  <option value="MO">Missouri</option>
							  <option value="MT">Montana</option>
							  <option value="NE">Nebraska</option>
							  <option value="NV">Nevada</option>
							  <option value="NH">New Hampshire</option>
							  <option value="NJ">New Jersey</option>
							  <option value="NM">New Mexico</option>
							  <option value="NY">New York</option>
							  <option value="NC">North Carolina</option>
							  <option value="ND">North Dakota</option>
							  <option value="OH">Ohio</option>
							  <option value="OK">Oklahoma</option>
							  <option value="OR">Oregon</option>
							  <option value="PA">Pennsylvania</option>
							  <option value="RI">Rhode Island</option>
							  <option value="SC">South Carolina</option>
							  <option value="SD">South Dakota</option>
							  <option value="TN">Tennessee</option>
							  <option value="TX">Texas</option>
							  <option value="UT">Utah</option>
							  <option value="VT">Vermont</option>
							  <option value="VA">Virginia</option>
							  <option value="WA">Washington</option>
							  <option value="WV">West Virginia</option>
							  <option value="WI">Wisconsin</option>
							  <option value="WY">Wyoming</option>
							</select>
		        </label>
	        </div>
	        <div className="sk-form__row">
	        	<label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            Phone number
		          </span>
		          <input type="text" className="sk-form__text-input form-control" name="phoneNumberForm" value={this.props.phoneNumberForm} onChange={this.props.handleFormChange} />
							{this.state.errors.phoneNumberForm && <span className="concierge-warning">{this.state.errors.phoneNumberForm}</span>}
		        </label>
		        <label className="sk-form__input-wrapper--third">
		          <span className="sk-form__input-label">
		            Birthday
		          </span>
		          <input type="date" className="sk-form__text-input form-control" name="birthdayForm" value={this.props.birthdayForm} onChange={this.props.handleFormChange} placeholder="yyyy-mm-dd" />
							{this.state.errors.birthdayForm && <span className="concierge-warning">{this.state.errors.birthdayForm}</span>}
		        </label>
	        </div>
				</div>
				<div className="form-navigation">
		    	<button type="button" className="sk-button--size-lg next" onClick={this.validate}>Next</button>
		    </div>
			</div>
		)
	}
}

export default IndividualInsuranceForm
