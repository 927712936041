import React from 'react';
import PropTypes from 'prop-types';

class CourseCheckoutPaymentComplete extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="checkout-insurance-container">
				<h3 className="insurance-header insurance-complete-header">
					<strong style={{padding: '1rem'}}>
					<svg className="insurace-checkmark">
					  <use xlinkHref="#check"/>
					</svg>
					</strong>
					Payment
					<svg className="onboarding__vet-app-1__secure-icon lock">
						<use xlinkHref="#lock"/>
					</svg>
					<button className="sk-link-button change-coverage-link" onClick={this.props.resetPaymentCompleted}>EDIT</button>
				</h3>
				<p className="insurance-choice">
					<img src={`https://s3.amazonaws.com/kitsplit/img/icons/cc-${this.props.creditCardBrand.toLowerCase().replace(/ /g,'')}-icon.png`} />
					<strong>**** **** **** {this.props.creditCardLast4}</strong> <span className="insurance-choice-cost">Expires: {this.props.creditCardExpiration}</span>
				</p>
				<div className="payment-complete-discounts">
					<div>
						{this.props.creditAmount > 0 ? <p>You have <strong>${this.props.creditAmount}</strong> in credit available</p> : ""}
						{this.props.creditAmount > 0 ? 
							<div className="credit-option">
								<button className="sk-link-button" onClick={this.props.resetPaymentCompleted}>
									USE CREDIT
								</button>
								or 
							</div>
						: ""}
						
						<button className="sk-link-button" onClick={this.props.resetPaymentCompleted}>
							USE COUPON
						</button>
					</div>
				</div>
			</div>
		)
	}
}

export default CourseCheckoutPaymentComplete
