import React from "react"
import PropTypes from "prop-types"
import yup from "yup";
import Recaptcha from "react-recaptcha";

class ProjectPickup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			recaptchaChecked: false
		};
	};

	validate() {
		var schema = yup.object().shape({
			name: yup.string().required("Please enter a name"),
		  email: yup.string().email("Please enter a valid email").required("Please enter a email"),
			phoneNumber: yup.string()
				.matches(/[0-9]{3}-?[0-9]{3}-?[0-9]{4}/, "Please enter a valid phone number")
				.required("Please enter a valid phone number"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.handleSubmitForm(event);
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	allowSubmission() {
		this.setState({recaptchaChecked: true});
	}

	render() {
		const context = this;
		return (
			<div>
		    <h5 className="form-subheader">Where can we send your quote?</h5>

		    <label className="sk-form__input-wrapper" htmlFor="name">
		      <span className="sk-form__input-label--size-lg">
		        Name
		      </span>
					{this.state.errors.name && <span className="concierge-warning">{this.state.errors.name}</span>}
		      <input type="text" className="sk-form__text-input form-control" name="name" value={this.props.name} onChange={this.props.handleChange} />
		    </label>
		    <label className="sk-form__input-wrapper" htmlFor="email">
		      <span className="sk-form__input-label--size-lg">
		        Email
		      </span>
					{this.state.errors.email && <span className="concierge-warning">{this.state.errors.email}</span>}
		      <input type="email" className="sk-form__text-input form-control" name="email" value={this.props.email} onChange={this.props.handleChange} />
		    </label>
		    <label className="sk-form__input-wrapper" htmlFor="phoneNumber">
		      <span className="sk-form__input-label--size-lg">
		        Phone Number
		      </span>
					{this.state.errors.phoneNumber && <span className="concierge-warning">{this.state.errors.phoneNumber}</span>}
		      <input type="text" className="sk-form__text-input form-control" name="phoneNumber" value={this.props.phoneNumber} onChange={this.props.handleChange} />
		    </label>

				<Recaptcha
					sitekey={this.props.reCaptchaSiteKey}
					render="explicit"
					verifyCallback={this.allowSubmission.bind(this)}
				/>

		    <div className="form-navigation">
		    	<button type="button" className="sk-link-button--color-edu previous" onClick={this.props.previousStep}>&lt; Back</button>
		    	<input type="button" ref="submitButton"className="sk-button--color-edu--size-lg" value="Submit" disabled={!this.state.recaptchaChecked} onClick={this.validate.bind(this)}/>
		    </div>
	    </div>
		)
	}
};

export default ProjectPickup
