import React from 'react'
import PropTypes from 'prop-types'

class ListingRequestInfoModal extends React.Component {
  constructor(props) {
    super(props);
  }

  initializeMinimap() {
    let target = this.refs.minimap;

    let mapOptions = {
      center: {
        lat: parseFloat(this.props.lat),
        lng: parseFloat(this.props.lng)
      },
      zoom: 15,
      zoomControl: false,
      scaleControl: true,
      maxZoom: 15,
      minZoom: 15,
      draggable: true,
      scrollwheel: false,
      panControl: true,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#c4df9d"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#caccf2"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#8bccf8"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    };

    var map = new google.maps.Map(target, mapOptions);

    // Place the marker
    var marker = new google.maps.Marker({
      map: map,
      position: map.center,
      icon: 'https://s3.amazonaws.com/kitsplit/img/icons/dark-teal-circle-white-camera-40.png'
    });
  }

  componentDidMount() {
    this.initializeMinimap();
  }

  listAccessories(truncated) {
    truncated = truncated || null;

    const accessories = this.props.accessories;
    if (accessories.length > 0) {
      var returnHTML = [];
      for (var i = 0; i < accessories.length; i++) {
        returnHTML.push(<li key={i} className="panel-description__text">{accessories[i]}</li>)
      }

      if (truncated === 'truncated')
        return returnHTML.splice(0, 3);

      return returnHTML;
    } else {
      return [];
    }
  }

  renderAcccessories() {
    if (this.listAccessories().length !== 0) {
      return (
        <div className="accessories-section">
          <h4 className="notes-heading">
            Includes ({this.listAccessories().length})
          </h4>

          <ul className="js-accessories-shown">
            { this.listAccessories('truncated') }
          </ul>

          <a href="#" className="js-view-accessories sk-link-button" onClick={showAllAccessories}>VIEW ALL</a>

          <ul className="js-accessories-addl hidden">
            { this.listAccessories() }
          </ul>

          <a href="#" className="js-hide-accessories hidden sk-link-button" onClick={hideAccessories}>VIEW LESS </a>
        </div>
      )
    }
  }

  renderResolutionValues() {
    const resolution_vals = this.props.resolution_filters;
    if (resolution_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Resolution</div>
          <div className="row panel-description__text">
            { _.map(resolution_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }

  renderPhotoOrVideoValues() {
    const photo_video_vals = this.props.photo_or_video_filters;
    if (photo_video_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Photo or Video</div>
          <div className="row panel-description__text">
            { _.map(photo_video_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }

  renderLensMountValues() {
    const lens_mount_vals = this.props.lens_mount_filters;
    if (lens_mount_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Lens Mount</div>
          <div className="row panel-description__text">
            { _.map(lens_mount_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }

  renderPrimeZoomValues() {
    const prime_zoom_vals = this.props.prime_or_zoom_filters;
    if (prime_zoom_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Prime or Zoom</div>
          <div className="row panel-description__text">
            { _.map(prime_zoom_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }
  renderMountValues() {
    const mount_vals = this.props.mount_filters;
    if (mount_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Mount</div>
          <div className="row panel-description__text">
            { _.map(mount_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }
  renderStudioTypeValues() {
    const studio_type_vals = this.props.studio_type_filters;
    if (studio_type_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Studio Type</div>
          <div className="row panel-description__text">
            { _.map(studio_type_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }
  renderStudioIncludesValues() {
    const studio_includes_vals = this.props.studio_includes_filters;
    if (studio_includes_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Studio Includes</div>
          <div className="row panel-description__text">
            { _.map(studio_includes_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }
  renderStandValues() {
    const stand_vals = this.props.stand_filters;
    if (stand_vals.length > 0) {
      return (
        <div className="col-xs-4">
          <div className="row headers">Stand</div>
          <div className="row panel-description__text">
            { _.map(stand_vals, 'title').join(', ') }
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="sk-modal__wrapper" id="listingRequestModal">
        <div className="sk-modal__card">
          <div className="sk-modal-close" ref="closeInfoModal">
            <a href="#" onClick={this.props.onModalClose}>
              <svg>
                <use xlinkHref="#x"/>
              </svg>
            </a>
          </div>

          <div className="sk-modal__body">
            <div className="panel-description">
              <h4><strong>{ this.props.listing_name }</strong></h4>

              <h4 className="notes-heading">Owner's Notes</h4>

              <div className="listing-short-desc">
                <div className="panel-description__text">
                  { this.props.description.substring(0, 30) + '...' }
                </div>
              </div>

              <div className="listing-full-desc hidden">
                <div className="panel-description__text">
                  { this.props.description }
                </div>
              </div>

              <a href="#" className="js-show-addl-desc sk-link-button" onClick={showFullDesc}>Read more</a>
              <a href="#" className="js-hide-addl-desc hidden sk-link-button" onClick={hideFullDesc}>Read less</a>

              { this.renderAcccessories() }

              <div className="listing-addl-info">

                <div className="row col-xs-12 filter-list">
                  <div className="col-xs-4">
                    <div className="row headers">Brand</div>
                    <div className="row panel-description__text">
                      { this.props.brand }
                    </div>
                  </div>

                  <div className="col-xs-4">
                    <div className="row headers">Model</div>
                    <div className="row panel-description__text">
                      { this.props.model }
                    </div>
                  </div>

                  <div className="col-xs-4">
                    <div className="row headers">Condition</div>
                    <div className="row panel-description__text">
                      { this.props.condition}
                  </div>
                </div>

                <div className="no-padding row col-xs-12 filter-list">
                  { this.renderResolutionValues() }
                  { this.renderPhotoOrVideoValues() }
                  { this.renderLensMountValues() }
                </div>
                <div className="space-above"></div>
                <div className="no-padding row col-xs-12 filter-list">
                  { this.renderPrimeZoomValues() }
                  { this.renderMountValues() }
                  { this.renderStudioTypeValues() }
                </div>

                <div className="no-padding row col-xs-12 filter-list">
                  { this.renderStandValues() }
                  { this.renderStudioIncludesValues() }
                </div>
              </div>
            </div>

            <h4 className="notes-heading">Description</h4>
            <div className="panel-description__text">
              { this.props.product_description }
            </div>
            <div>
              <div id="mini-map-container" ref="minimap"></div>
            </div>
          </div>
        </div>
      </div>

      

      
      </div>
    )
  }
}

export default ListingRequestInfoModal
