import React from 'react';
import PropTypes from 'prop-types';
// import CheckoutLoginModal from './CourseCheckout/CheckoutLoginModal'

class CourseCheckoutCreateUser extends React.Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     showLoginModal: false
        // }
    }

    showLoginModal() {
        $('#strippedUserLoginCourseForm').modal('show');
    }

    render() {
        return (
            <div className="checkout-create-user-container">
                <h3 className="insurance-header">Contact Information</h3>
                <p className="course-checkout-login">
                    HAVE AN ACCOUNT?
                    <a className="sk-link-button" onClick={this.showLoginModal}>LOG IN</a>
                </p>
                <div className="sk-form__wrapper">
                    <div className="sk-form__row">
                        <label className="sk-form__input-wrapper--third">
                            <span className="sk-form__input-label">
                                First Name
                            </span>
                            <input type="text" className="sk-form__text-input form-control" name="firstNameForm" value={this.props.firstNameForm} onChange={this.props.handleFormChange} />
                        </label>
                        <label className="sk-form__input-wrapper--third">
                            <span className="sk-form__input-label">
                                Last Name
                            </span>
                            <input type="text" className="sk-form__text-input form-control" name="lastNameForm" value={this.props.lastNameForm} onChange={this.props.handleFormChange} />
                            
                        </label>
                    </div>
                    <div className="sk-form__row">
                        <label className="sk-form__input-wrapper--third">
                            <span className="sk-form__input-label">
                                Email Address
                            </span>
                            <input type="text" className="sk-form__text-input form-control" name="signUpEmail" value={this.props.signUpEmail} onChange={this.props.handleFormChange} onKeyUp={this.props.checkUniqueEmailForClassGuestCheckout(this.props.signUpEmail)} />
                            <div id="email-already-taken-msg" className="sk-card__errors__error noDisplay">
                              An account already exists with this email address. Please log in.
                            </div>
                            <div id="email-invalid" className="sk-card__errors__error noDisplay">
                              Please enter a valid email address.
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

export default CourseCheckoutCreateUser