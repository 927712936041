import React from "react"
import PropTypes from "prop-types"

class ConciergeComplete extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		var user = this.props.user;
		var nextSteps = "";
		if (user) {
			nextSteps = <div><p>Until then, check out some of the gear currently available to rent on KitSplit.</p>
			    <a href="/search" className="sk-button--color-edu--size-lg">Browse Gear</a></div>;
		} else {
			nextSteps = <div><p>In the mean time, get a head start by creating a KitSplit account and completing your profile.</p>
					<p className="bid-request-notice"><b>Want to receive bids from rental hourses?</b></p>
					<p>Login or sign up to start receiving quotes.</p>
			    	<a href={`/users/sign_up?bid=${this.props.bidRequestId}`} className="sk-button--color-edu--size-lg concierge-sign-up">
			      		Create My Account
			     	 </a>
			  		<a href={`/users/sign_in?bid=${this.props.bidRequestId}`} className="sk-link-button--color-edu">Log Into My Account</a></div>;
		}

		return (
			<div className="concierge-complete">
			  <h3 className="form-header">Thanks for the request!</h3>
			  <p>A KitSplit Rental Agent will review your rental needs and will be in touch soon.</p>
	 
			  {nextSteps}

			</div>
		)
	}
}

export default ConciergeComplete