import React from 'react'
import PropTypes from 'prop-types'
import DamageWaiver from './DamageWaiver'
import IndividualInsurance from './IndividualInsurance'

class PurchaseInsurance extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="insurance-option-details">
				<div>
					<p className="insurance-section-heading">{parseInt(this.props.totalReplacementValue) > 10000 ? 'Purchase Short-term Insurance' : 'Purchase Damage Waiver' }</p>
					<button className="sk-link-button change-coverage-link" onClick={this.props.changeInsuranceOption}>CHANGE COVERAGE TYPE</button>
				</div>
				<hr />
				<div className="insurance-form">
					{parseInt(this.props.totalReplacementValue) > 10000 ?
						<IndividualInsurance
							submitIndividualInsuranceForm={this.props.submitIndividualInsuranceForm}
							handleFormChange={this.props.handleFormChange}
							handleCheckbox={this.props.handleCheckbox}
							agreeToTerms={this.props.agreeToTerms}
							setInternationalInsuranceOption={this.props.setInternationalInsuranceOption}
							setTheftFromAnyVehicleOption={this.props.setTheftFromAnyVehicleOption}
							accountTypeForm={this.props.accountTypeForm}
							firstNameForm={this.props.firstNameForm}
							lastNameForm={this.props.lastNameForm}
							address1Form={this.props.address1Form}
							address2Form={this.props.address2Form}
							zipCodeForm={this.props.zipCodeForm}
							cityForm={this.props.cityForm}
							stateForm={this.props.stateForm}
							phoneNumberForm={this.props.phoneNumberForm}
							birthdayForm={this.props.birthdayForm}
							insuranceCarrier={this.props.insuranceCarrier}
							policyStartDate={this.props.policyStartDate}
							policyEndDate={this.props.policyEndDate}
							policyNumber={this.props.policyNumber}
							dateOfLoss={this.props.dateOfLoss}
							itemsInClaim={this.props.itemsInClaim}
							claimCircumstances={this.props.claimCircumstances}
							totalClaimPayout={this.props.totalClaimPayout}
							internationalDeductibleSelected={this.props.internationalDeductibleSelected}
							internationalDeductibleOption={this.props.internationalDeductibleOption}
							theftFromAnyVehicleDeductibleSelected={this.props.theftFromAnyVehicleDeductibleSelected}
							theftFromAnyVehicleOption={this.props.theftFromAnyVehicleOption}
							theftFromUnlockedVehicleSelected={this.props.theftFromUnlockedVehicleSelected}
							updateIndividualInsuranceCost={this.props.updateIndividualInsuranceCost}
							existingPolicyId={this.props.existingPolicyId}
							existingPolicyReplacementValue={this.props.existingPolicyReplacementValue}
						/> :
						<DamageWaiver
							damageWaiverEstimate={this.props.damageWaiverEstimate}
							submitIndividualDamageWaiverForm={this.props.submitIndividualDamageWaiverForm}
						/>
					}
				</div>
			</div>
		)
	}
}

export default PurchaseInsurance
