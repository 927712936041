import React from 'react';
import PropTypes from 'prop-types';

class CheckoutInsuranceComplete extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="checkout-insurance-container">
				<h3 className="insurance-header insurance-complete-header">
					<strong style={{padding: '1rem'}}>
						<svg className="insurace-checkmark">
						  <use xlinkHref="#check"/>
						</svg>
					</strong>
					Coverage
					<button className="sk-link-button change-coverage-link" onClick={this.props.editInsurance}>EDIT</button>
				</h3>
				<p className="insurance-choice">
					<strong>{this.props.insuranceOption}</strong>
					<span className="insurance-choice-cost">Cost: ${parseFloat(this.props.insuranceAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>
				</p>
			</div>
		)
	}
}

export default CheckoutInsuranceComplete
