import React from 'react'
import PropTypes from 'prop-types'
import CartListingsSummary from './Checkout/CartListingsSummary'
import PriceSummary from './Checkout/PriceSummary'
import CheckoutInsurance from './Checkout/CheckoutInsurance'
import CheckoutPayment from './Checkout/CheckoutPayment'
import CheckoutInsuranceComplete from './Checkout/CheckoutInsuranceComplete'
import CheckoutPaymentComplete from './Checkout/CheckoutPaymentComplete'
import ChangeDateModal from './Checkout/ChangeDateModal'

import { OverlayTrigger, Tooltip } from "react-bootstrap"

class CheckoutContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedInsurance: this.props.validInsurance,
			previewInsuranceOption: 0,
			insuranceOption: this.props.insuranceSelected,
			insuranceAmount: this.props.policyExtensionCost > 0 ? this.props.policyExtensionCost : this.props.insuranceCost,
			accountTypeForm: "sole_proprietorship",
			firstNameForm: this.props.name,
			lastNameForm: this.props.lastName,
			address1Form: this.props.address,
			address2Form: this.props.addressTwo,
			zipCodeForm: this.props.zipCode,
			cityForm: this.props.city,
			stateForm: this.props.state,
			phoneNumberForm: this.props.phoneNumber,
			birthdayForm: this.props.birthday,
			agreeToTerms: false,
			insuranceCarrier: "",
			policyNumber: "",
			policyStartDate: "",
			policyEndDate: "",
			dateOfLoss: "",
			itemsInClaim: "",
			claimCircumstances: "",
			totalClaimPayout: "",
			internationalDeductibleSelected: false,
			internationalDeductibleOption: "international_option2",
			theftFromAnyVehicleDeductibleSelected: false,
			theftFromAnyVehicleOption: "TFAV_option2",
			theftFromUnlockedVehicleSelected: false,
			couponCode: "",
			couponPercent: "",
			couponDiscountAmount: "",
			creditAmountAvailable: this.props.creditAmountAvailable,
			creditAmountApplied: this.props.referralCreditApplied/100,
			creditCardBrand: this.props.selectedCardBrand,
			creditCardLast4: this.props.selectedCardLast4,
			creditCardExpiration: this.props.selectedCardExpiration,
			discountTypeApplied: "",
			discountAmount: "",
			discountErrors: "",
			paymentCompleted: this.props.validPayment,
			paymentCardSelected: "",
			creditCardId: "",
			checkoutTotal: this.props.total,
			showDateModal: false,
			startDate: this.props.startDate,
			startTime: this.props.startTime,
			endDate: this.props.endDate,
			endTime: this.props.endTime,
      startDateTime: this.props.startDateTime,
      userCoiOverride: this.props.userCoiOverride,
			subtotal: this.props.subtotal,
			convenienceFee: this.props.convenienceFee,
			tax: this.props.tax,
			conversationsList: this.props.conversationsList,
			setDefaultCard: !this.props.validPayment,
			heardAboutKitsplit: this.props.userHeardAboutKitsplit == "" ? "Select an option" : this.props.userHeardAboutKitsplit,
			heardAboutKitsplitOther: this.props.heardAboutKitsplitOther,
			autoDiscountedConversation: this.props.autoDiscountedConversation,
			bidConversationId: this.props.bidConversationId ? this.props.bidConversationId : ""
		};

		this.submitFullDeposit = this.submitFullDeposit.bind(this);
		this.submitProductionInsuranceForm = this.submitProductionInsuranceForm.bind(this);
		this.submitIndividualDamageWaiverForm = this.submitIndividualDamageWaiverForm.bind(this);
		this.submitIndividualInsuranceForm = this.submitIndividualInsuranceForm.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.setInternationalInsuranceOption = this.setInternationalInsuranceOption.bind(this);
		this.setTheftFromAnyVehicleOption = this.setTheftFromAnyVehicleOption.bind(this);
		this.editInsurance = this.editInsurance.bind(this);
		this.paymentCompleted = this.paymentCompleted.bind(this);
		this.resetPaymentCompleted = this.resetPaymentCompleted.bind(this);
		this.requestRental = this.requestRental.bind(this);
		this.applyReferralCredit = this.applyReferralCredit.bind(this);
		this.validateCouponCode = this.validateCouponCode.bind(this);
		this.applyCouponToConversationsInCart = this.applyCouponToConversationsInCart.bind(this);
		this.changeCartDates = this.changeCartDates.bind(this);
		this.setInitialIndividualInsuranceCost = this.setInitialIndividualInsuranceCost.bind(this);
		this.updateIndividualInsuranceCost = this.updateIndividualInsuranceCost.bind(this);
		this.resetDiscountApplied = this.resetDiscountApplied.bind(this);
	}


	submitFullDeposit(e) {
		e.preventDefault();

		const url = "/cart/add-full-deposits";

		const data = {};
		if(this.state.bidConversationId != "") {
			data["bid_conversation_id"] = this.props.bidConversationId
		}

		data["total_replacement_value"] = this.props.totalReplacementValue

		$.ajax({
	    type: 'PATCH',
	    data: data,
	    url: url,
	    dataType: 'json',
	    success: function(res) {
	    	// debugger
	      if(res.success == true) {
	      	this.setState({
	      		selectedInsurance: true,
	      		insuranceAmount: this.props.totalReplacementValue,
	      		insuranceOption: "Leave Full Deposit",
	      		checkoutTotal: res.checkout_total
	      	})
	      	document.body.scrollTop = document.documentElement.scrollTop = 0;
	      } else {
	        // error
	      }
	    }.bind(this)
  	});
	}

	submitProductionInsuranceForm(e) {
		e.preventDefault();
		const url = "/cart/add-production-insurances/";
		const data = {};
		if(this.state.bidConversationId != "") {
			data["conversation_id"] = this.props.bidConversationId
		}

		$.ajax({
	    type: 'PATCH',
	    data: data,
	    url: url,
	    dataType: 'json',
	    success: function(res){
	      if(res.success == true) {
	        // We're good! Insurance is set, we can "activate" continue to payments & delivery
	        this.setState({
	      		selectedInsurance: true,
	      		insuranceAmount: 0,
	      		insuranceOption: "Provide Your Own Insurance Policy",
	      		checkoutTotal: res.checkout_total
	      	})
	      	document.body.scrollTop = document.documentElement.scrollTop = 0;
	      } else {
	        // error
	      }
	    }.bind(this)
	  });
	}

	submitIndividualDamageWaiverForm(e) {
		e.preventDefault();
		const url = "/cart/add-damage-waivers/";

		const data = {};
		if(this.state.bidConversationId != "") {
			data["bid_conversation_id"] = this.props.bidConversationId
		}

		data["total_replacement_value"] = this.props.totalReplacementValue

		$.ajax({
	    type: 'PATCH',
	    data: data,
	    url: url,
	    dataType: 'json',
	    success: function(res) {
	      if(res.success == true) {
	        // We're good! Insurance is set, we can "activate" continue to payments & delivery
	        this.setState({
	      		selectedInsurance: true,
	      		insuranceOption: "Purchase Damage Coverage",
	      		insuranceAmount: res.insurance_cost,
	      		checkoutTotal: res.checkout_total
	      	})
	      	document.body.scrollTop = document.documentElement.scrollTop = 0;
	      } else {
	        // error
	      }
	    }.bind(this)
	  });
	}

	submitIndividualInsuranceForm() {
	  const url = "/cart/add-individual-insurances/";
	  $.ajax({
	    type: 'PATCH',
	    data: {
	      first_name: this.state.firstNameForm,
		  last_name: this.state.lastNameForm,
		  account_type: this.state.accountTypeForm,
		  address1: this.state.address1Form,
	      address2: this.state.address2Form,
	      zip_code: this.state.zipCodeForm,
		  city: this.state.cityForm,
		  state: this.state.stateForm,
	      phone_number: this.state.phoneNumberForm,
	      birthday: this.state.birthdayForm,
	      equipment_claims_last_five_years_over_5k: this.state.insuranceCarrier != "" ? true : false,
	      claims_over_5k_carrier: this.state.insuranceCarrier,
	      claims_over_5k_policy_number: this.state.policyNumber,
	      claims_over_5k_date_of_loss: this.state.dateOfLoss,
	      claims_over_5k_list: this.state.itemsInClaim,
	      claims_over_5k_damages_explanation: this.state.claimCircumstances,
	      out_of_country: this.state.internationalDeductibleSelected,
	      international_deductible_option: this.state.internationalDeductibleOption,
	      theft_from_any_vehicle: this.state.theftFromAnyVehicleDeductibleSelected,
	      theft_from_any_vehicle_option: this.state.theftFromAnyVehicleOption,
		  	unlocked_vehicle: this.state.theftFromUnlockedVehicleSelected,
		  	total_replacement_value: this.props.totalReplacementValue,
				existing_policy_id: this.props.existingPolicyId,
				bid_conversation_id: this.state.bidConversationId
	    },
	    url: url,
	    dataType: 'json',
			success: function(res) {
	      if(res.success == true) {
	        // We're good! Insurance is set, we can "activate" continue to payments & delivery
	        this.setState({
	      		selectedInsurance: true,
	      		insuranceOption: res.insurance_option,
	      		insuranceAmount: res.insurance_cost,
	      		checkoutTotal: res.checkout_total
			  })
			  document.body.scrollTop = document.documentElement.scrollTop = 0;
	      } else {
	        // error
	      }
	    }.bind(this)
	  });
	}

	requestRental(e) {
		e.preventDefault();
		
		const valid_insurance = cartHasValidInsurance(this.state.bidConversationId);
		
		const url = '/request-rentals';
		const showModal = showOnboardingModal(this.props.renter_id, this.props.totalReplacementValue);

		if(valid_insurance && this.state.paymentCompleted) {
			if(this.state.discountTypeApplied == "coupon") {
				const coupon = this.state.couponCode
				const url = "/cart/apply-coupon-to-cart/" + coupon;

				$.ajax({
				type: 'PATCH',
				url: url,
				dataType: 'json',
				success: function (res) {
					if (res.coupon_amt !== 0) {
					// Good coupon. Update exchange visuals
					this.setState({
						discountTypeApplied: "coupon",
						discountErrors: ""
					})
					} else {
					// Bad Coupon. Display warning message
					this.setState({
						discountErrors: "Discount not applied, check coupon and try again."
					})
					}
				}.bind(this),
			});
			}

			if(showModal == true) {
				const options = {
            show: true,
            backdrop: 'static',
            keyboard: false
          };

         $('#requestRentalOnboardingModal').modal(options);
			} else {
				$.ajax({
			    type: 'PATCH',
			    data: {
			    	cc_last_4: this.state.creditCardLast4,
			    	cc_brand: this.state.creditCardBrand,
						cc_expiration: this.state.creditCardExpiration,
						conversation_id: this.state.bidConversationId,
						user: {
							heard_about_kitsplit_channel: this.state.heardAboutKitsplit == "Select an option" ? "" : this.state.heardAboutKitsplit,
							heard_about_kitsplit_channel_other: this.state.heardAboutKitsplitOther
						}
			    },
			    url: url,
			    dataType: 'json',
			    success: function(res) {
			      if(res.success == true) {
			      	window.location.href = res.path;
			      } else {
			        // error
			      }
			    }.bind(this)
			  });
			};
		}
	}

	editInsurance() {
		this.setState({
			selectedInsurance: false,
			insuranceAmount: this.props.insuranceCost
		})
	}

	handleFormChange(event) {
		this.setState({
			[event.target.name] : event.target.value
		})
	}

	handleStateChange(newState) {
		this.setState(newState);
	}

	handleCheckbox(event) {
		this.setState({
			[event.target.name] : event.target.checked
		});
	}

	setInternationalInsuranceOption(option) {
		this.setState({
			internationalDeductibleOption: option
		});
	}

	setTheftFromAnyVehicleOption(option) {
		this.setState({
			theftFromAnyVehicleOption: option
		});
	}

	validateCouponCode() {
		const coupon = this.state.couponCode
		const url = "/cart/calculate_coupon_amt/" + coupon;

		$.ajax({
			type: 'PATCH',
			url: url,
			dataType: 'json',
			success: function (res) {
				if (res.coupon_amt !== 0) {
					// Good coupon. Update exchange visuals
					this.setState({
						discountTypeApplied: "coupon",
						discountErrors: "",
						couponPercent: res.coupon_percent_off,
						couponDiscountAmount: res.coupon_amt,
						checkoutTotal: this.state.checkoutTotal - res.coupon_amt
					})
				} else {
					// Bad Coupon. Display warning message
					this.setState({
						discountErrors: "Discount not applied, please make sure you have entered it correctly."
					})
				}
			}.bind(this),
			error: function(res) {
				// Bad Coupon. Display warning message
			}
		});
	}

	applyCouponToConversationsInCart() {
    // TODO this function isn't getting called so we copy/pasted this code in its place
	  // const coupon = this.state.couponCode
	  // const url = "/cart/apply-coupon-to-cart/" + coupon;

	  // $.ajax({
	  //   type: 'PATCH',
	  //   url: url,
	  //   dataType: 'json',
	  //   success: function (res) {
	  //     if (res.coupon_amt !== 0) {
	  //       // Good coupon. Update exchange visuals
			// 		this.setState({
			// 			discountTypeApplied: "coupon",
			// 			discountErrors: ""
			// 		})
	  //     } else {
	  //       // Bad Coupon. Display warning message
			// 		this.setState({
			// 			discountErrors: "Discount not applied, check coupon and try again."
			// 		})
	  //     }
	  //   }.bind(this),
	  // });
	}

	applyReferralCredit() {
	  const creditAmt = this.state.creditAmountAvailable
	  const creditAmtApplied = this.state.creditAmountApplied
	  const data = {}

	  if (creditAmtApplied > creditAmt) {
	    this.setState({
				discountErrors: "The amount you entered is more than you have available."
			})
	  } else if (creditAmtApplied <= 0) {
			this.setState({
			 discountErrors: "Please enter an amount greater than 0."
		 })
	  } else {
	    data["credit_amt"] = creditAmtApplied
	    $.ajax({
	      type: 'PATCH',
	      url: Routes.apply_referral_credit_path(),
	      dataType: 'json',
	      data: data,
	      success: function (res) {
	        if (res.credit_amt_applied !== 0) {
	          // Credit was applied. Update exchange visuals
	          this.setState({
							discountTypeApplied: "credit",
							discountErrors: "",
							creditAmountAvailable: res.remaining_credit,
							creditAmountApplied: res.credit_amt_applied,
							checkoutTotal: this.state.checkoutTotal - res.credit_amt_applied
						})
	        }
	      }.bind(this),
	    });
	  };
	}

	resetDiscountApplied() {
		if(this.state.discountTypeApplied == "credit") {
			let updatedTotal = this.state.checkoutTotal + this.state.creditAmountApplied
			this.setState({
				creditAmountAvailable: this.state.creditAmountApplied,
				discountTypeApplied: "",
				checkoutTotal: updatedTotal
			})
		} else if(this.state.discountTypeApplied == "coupon") {
			let updatedTotal = this.state.checkoutTotal + this.state.couponDiscountAmount
			this.setState({
				discountTypeApplied: "",
				checkoutTotal: updatedTotal
			})
		}
	}

	paymentCompleted(creditCardBrand, creditCardLast4, creditCardExpiration) {
		this.setState({
			paymentCompleted: true,
			setDefaultCard: false,
			creditCardBrand: creditCardBrand,
			creditCardLast4: creditCardLast4,
			creditCardExpiration: creditCardExpiration
		})
	}

	resetPaymentCompleted(){
		this.setState({
			paymentCompleted: false,
			creditCardBrand: "",
			creditCardLast4: "",
			creditCardExpiration: ""
		})
	}

	checkCheckoutCompleted() {
		if (this.state.paymentCompleted && this.state.selectedInsurance) {
			// if (this.state.heardAboutKitsplit == "other" && !this.state.heardAboutKitsplitOther) {
			// 	return false;
			// } else {
			// 	return true;
			// }
      return true;
		} else {
			return false;
		}
	}

	changeCartDates() {
		let url = "/cart/update-conversations-in-cart-date"
		const data = {
			'conversation': {
				// start_date: new Date(Date.parse(this.state.startDate)),
				// start_date: moment(this.state.startDate, "YYYY-MM-DD").toDate(),
				start_date: $('#start_date').val(),
				start_time: $('#start_time').val(),
				// end_date: new Date(Date.parse(this.state.endDate)),
				end_date: $('#end_date').val(),
				end_time: $('#end_time').val()
			},
			'date': {
				start_time: this.state.startTime,
				end_time: this.state.endTime
			}
		}

		$.ajax({
			type: 'PATCH',
			url: url,
			data: data,
			dataType: 'json',
			success: function (res) {
				this.setState({
					startDate: $.datepicker.formatDate("M d, yy", new Date(res.startDate+'T00:00:00')),
					startTime: res.startTime,
					endDate: $.datepicker.formatDate("M d, yy", new Date(res.endDate+'T00:00:00')),
					endTime: res.endTime,
					subtotal: res.cart_subtotal,
					convenienceFee: res.service_fee,
					tax: res.tax_total,
					couponDiscountAmount: res.coupon_amt,
					checkoutTotal: res.checkout_total,
					conversationsList: res.conversations_display
				});
				this.handleStateChange({showDateModal: false});
			}.bind(this),
		});
	}

	setInitialIndividualInsuranceCost() {
		// and update total
		this.setState({
			insuranceAmount: this.props.totalReplacementValue > 10000 ? this.props.individualRentalPremium : this.props.damageWaiverEstimate,
			checkoutTotal: this.state.checkoutTotal + (this.props.totalReplacementValue > 10000 ? this.props.individualRentalPremium : this.props.damageWaiverEstimate)
		})
	}

	updateIndividualInsuranceCost(event) {
		this.setState({
			[event.target.name] : event.target.value,
		})
	}

	// ********* function commented out for rdday ***********
	// getInsuranceAmount() {
	// 	if(this.state.insuranceAmount == "") {
	// 		if(this.state.insuranceOption == "Purchase Individual Damage Coverage" && this.props.totalReplacementValue > 10000) {
	// 			let deductible_cost = 0
	// 			let heffinsAdminFee = 0

	// 			// # From Heffins pdf document, 10% of premium is either added or deducted to premium based on option selected
	// 			let deductible_premium_percentage = this.props.individualRentalPremium * 0.1
	// 			let updated_insurance_cost = 0

	// 			if (this.state.previewInsuranceOption == 1) {
	// 				if (this.state.internationalDeductibleSelected) {
	// 					if (this.state.internationalDeductibleOption == "international_option1") {
	// 						deductible_cost += deductible_premium_percentage
	// 					} else if (this.state.internationalDeductibleOption == "international_option3") {
	// 						deductible_cost -= deductible_premium_percentage
	// 					}
	// 				}
	// 				if (this.state.theftFromAnyVehicleDeductibleSelected) {
	// 					if (this.state.theftFromAnyVehicleOption == "TFAV_option1") {
	// 						deductible_cost += deductible_premium_percentage
	// 					} else if (this.state.theftFromAnyVehicleOption == "TFAV_option3") {
	// 						deductible_cost -= deductible_premium_percentage
	// 					}
	// 				}
	// 				if (this.state.theftFromUnlockedVehicleSelected) {
	// 					deductible_cost += this.props.individualRentalPremium * 0.19
	// 				}

	// 				updated_insurance_cost = this.props.individualRentalPremium + deductible_cost + heffinsAdminFee
	// 			}

	// 			return updated_insurance_cost;

	// 			// this.setState({
	// 			// 	checkoutTotal: this.state.checkoutTotal + updated_insurance_cost
	// 			// })
				
	// 		} else if (this.state.insuranceOption == "Purchase Damage Coverage") {
	// 			let updated_insurance_cost = parseFloat(this.props.damageWaiverEstimate)

	// 			return updated_insurance_cost
	// 		} else if (this.state.insuranceOption == "Leave Full Deposit") {
	// 			let updated_insurance_cost = parseFloat(this.props.totalReplacementValue)

	// 			return updated_insurance_cost
	// 		} else {
	// 			return 0
	// 		}
	// 	} else {
	// 		return this.state.insuranceAmount
	// 	}
	// }

	// *****************

	getTotalCost() {
		if (this.state.insuranceOption == "Purchase Individual Damage Coverage" || this.state.insuranceOption == "Additional Gear Coverage On Existing Policy"){
			let checkoutTotal = parseFloat(this.state.insuranceAmount) + parseFloat(this.state.checkoutTotal)
			return checkoutTotal
		} else {
			return this.state.checkoutTotal
		}
	}

	render() {
		const requestRentalTooltip = (
		  <Tooltip id="tooltip">
		    Please complete all fields before requesting rental 
		  </Tooltip>
		);

		return (
			<div className="col-md-12">
				<h1 className="checkout-header">Checkout</h1>
				<p className="conversation-dates-container">
					<span className="dates">{this.state.startDate}</span> {this.state.startTime} - <span className="dates">{this.state.endDate}</span> {this.state.endTime}
					<a href="/cart" className="edit-dates sk-link-button">EDIT</a>
					<ChangeDateModal visible={this.state.showDateModal} onClose={() => {
						this.handleStateChange({showDateModal: false});
						}}
						handleFormChange={this.handleFormChange}
						changeCartDates={this.changeCartDates}
						startDate={this.state.startDate}
						startTime={this.state.startTime}
						endDate={this.state.endDate}
						endTime={this.state.endTime}
					/>
				</p>
				<div className="row col-md-12">
					<div className="checkout-left col-md-8">

						{this.state.selectedInsurance ? <CheckoutInsuranceComplete
								editInsurance={this.editInsurance}
								insuranceOption={this.state.insuranceOption}
								insuranceAmount={this.state.insuranceAmount}
							/> :
							<CheckoutInsurance
								selectedInsurance={this.state.selectedInsurance}
								previewInsuranceOption={this.state.previewInsuranceOption}
								totalReplacementValue={this.props.totalReplacementValue}
								conversationRentalLength = {this.props.conversationRentalLength}
								individualRentalPremium = {this.props.individualRentalPremium}
								damageWaiverEstimate = {this.props.damageWaiverEstimate}
								submitFullDeposit={this.submitFullDeposit}
								submitProductionInsuranceForm={this.submitProductionInsuranceForm}
								insuranceCoverageCeiling={this.props.insuranceCoverageCeiling}
								submitIndividualDamageWaiverForm={this.submitIndividualDamageWaiverForm}
								submitIndividualInsuranceForm={this.submitIndividualInsuranceForm}
								handleFormChange={this.handleFormChange}
								handleStateChange={this.handleStateChange}
								handleCheckbox={this.handleCheckbox}
								setInternationalInsuranceOption={this.setInternationalInsuranceOption}
								setTheftFromAnyVehicleOption={this.setTheftFromAnyVehicleOption}
								agreeToTerms={this.state.agreeToTerms}
								accountType={this.state.accountTypeForm}
								firstNameForm={this.state.firstNameForm}
								lastNameForm={this.state.lastNameForm}
								address1Form={this.state.address1Form}
								address2Form={this.state.address2Form}
								zipCodeForm={this.state.zipCodeForm}
								cityForm={this.state.cityForm}
								stateForm={this.state.stateForm}
								phoneNumberForm={this.state.phoneNumberForm}
								birthdayForm={this.state.birthdayForm}
								insuranceCarrier={this.state.insuranceCarrier}
								policyStartDate={this.state.policyStartDate}
								policyEndDate={this.state.policyEndDate}
								policyNumber={this.state.policyNumber}
								dateOfLoss={this.state.dateOfLoss}
								itemsInClaim={this.state.itemsInClaim}
								claimCircumstances={this.state.claimCircumstances}
								totalClaimPayout={this.state.totalClaimPayout}
								internationalDeductibleSelected={this.state.internationalDeductibleSelected}
								internationalDeductibleOption={this.state.internationalDeductibleOption}
								theftFromAnyVehicleDeductibleSelected={this.state.theftFromAnyVehicleDeductibleSelected}
								theftFromAnyVehicleOption={this.state.theftFromAnyVehicleOption}
								theftFromUnlockedVehicleSelected={this.state.theftFromUnlockedVehicleSelected}
								setInitialIndividualInsuranceCost={this.setInitialIndividualInsuranceCost}
								updateIndividualInsuranceCost={this.updateIndividualInsuranceCost}
								existingPolicyId={this.props.existingPolicyId}
								rentalStartDate={this.state.startDate}
                startDateTime={this.state.startDateTime}
                userCoiOverride={this.props.userCoiOverride}
								policyExtensionCost={this.props.policyExtensionCost}
								existingPolicyReplacementValue={this.props.existingPolicyReplacementValue}
								cartHasDrone={this.props.cartHasDrone}
							/>
						}
						{ this.state.paymentCompleted ?
							<CheckoutPaymentComplete
								resetPaymentCompleted={this.resetPaymentCompleted}
								creditCardBrand={this.state.creditCardBrand}
								creditCardLast4={this.state.creditCardLast4}
								creditCardExpiration={this.state.creditCardExpiration}
								creditAmount={this.state.creditAmountAvailable}
                autoDiscountedConversation={this.state.autoDiscountedConversation}
							/> :
							<CheckoutPayment
								cc = {this.props.cc}
								selectedInsurance={this.state.selectedInsurance}
								paymentCompleted={this.paymentCompleted}
								handleFormChange={this.handleFormChange}
								creditAmount={this.state.creditAmountAvailable}
								couponCode={this.state.couponCode}
								creditAmountApplied={this.state.creditAmountApplied}
								applyReferralCredit={this.applyReferralCredit}
								validateCouponCode={this.validateCouponCode}
								discountTypeApplied={this.state.discountTypeApplied}
								resetDiscountApplied={this.resetDiscountApplied}
								discountErrors={this.state.discountErrors}
								setDefaultCard={this.state.setDefaultCard}
								autoDiscountedConversation={this.state.autoDiscountedConversation}
								bidConversationId={this.state.bidConversationId}
							/>
						}
						{ !this.checkCheckoutCompleted() ? 
								<div className="checkout-request-btn">
									<OverlayTrigger placement="bottom" overlay={requestRentalTooltip}>
										<div>
											<button disabled={!this.checkCheckoutCompleted()} className="btn sk-button--size-xlg" onClick={this.requestRental}>REQUEST RENTAL</button>
											</div>
									</OverlayTrigger>
								</div>
							: 
							<div className="checkout-request-btn">
								<button disabled={!this.checkCheckoutCompleted()} className="btn sk-button--size-xlg" onClick={this.requestRental}>REQUEST RENTAL</button>
							</div>
						}
						{ this.checkCheckoutCompleted() ?
							<p className="request-rental-text">
								Your card will be authorized for the total payment of (${parseFloat(this.getTotalCost()).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}) when you request this rental, but it will not be charged until the rental is accepted.
							</p>
							:
							<div></div>
						}
					</div>
					<div className="checkout-right col-md-4">
						<CartListingsSummary
							conversations={this.props.conversations}
							conversationsList={this.state.conversationsList}
							items={this.props.items}
						/>
						<PriceSummary
							subtotal={this.state.subtotal}
							convenienceFee={this.state.convenienceFee}
							tax={this.state.tax}
							insuranceAmount={this.state.insuranceOption == null ? 0 : this.state.insuranceAmount}
							discountTypeApplied={this.state.discountTypeApplied}
							couponAmt={this.state.couponDiscountAmount}
							ownerDiscount={this.props.ownerDiscountAmt}
							couponPercent={this.state.couponPercent}
							referralCredit={this.state.creditAmountApplied}
							total={parseFloat(this.getTotalCost())}
							totalReplacementValue={this.props.totalReplacementValue}
							insuranceOption={this.state.insuranceOption}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default CheckoutContainer
