import React from "react"
import PropTypes from "prop-types"
import yup from "yup";

import RatingStars from './RatingStars';
import BidNotificationPreferencesModal from './BidNotificationPreferencesModal';
import { OverlayTrigger, Tooltip } from "react-bootstrap"

class ProjectsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: "new",
            minBudget: 0,
            location: "All",
            projects: [],
            bidNotificationModal: false,
            userNotificationPreferences: this.props.userNotificationPreferences,
        };

        this.setNotification = this.setNotification.bind(this);
        this.changeNotificationPreferences = this.changeNotificationPreferences.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
    }

    componentWillMount() {
        this.fetchOpenBids();
        $('#vp-fee-glyphicon').tooltip();
    }

    fetchOpenBids() {
        var url = new URL("/projects/load-open-projects", window.location.href);
        url.searchParams.append("sort", this.state.sortBy);
        url.searchParams.append("min_budget", this.state.minBudget);
        url.searchParams.append("location", this.state.location);
        fetch(url)
            .then(response => response.json())
            .then(response => {                
                this.setState({projects: response});
            })
    }

    setBudget(min) {
        this.setState(
            {minBudget: min},
            () => this.fetchOpenBids());
    }

    setSort(sort) {
        this.setState(
            {sortBy: sort},
            () => this.fetchOpenBids());
    }

    setLocation(location) {
        this.setState(
            {location: location},
            () => this.fetchOpenBids());
    }

    showBidNotification(visible) {
        this.setState({
            bidNotificationModal: visible
        });
    }

    setNotification(type) {
        this.setState({
            userNotificationPreferences: type
        });
    }

    changeNotificationPreferences() {
        let url = '/projects/change-user-notification-preferences';
        fetch(url, {
                method: "post",
                body: JSON.stringify({
                    notification_type: this.state.userNotificationPreferences,
                    authenticity_token: AUTH_TOKEN
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
            })
            .then(response => response.json())
            .then(response => {
                this.setState({bidNotificationModal: false});
            })
    }

    deleteProject(project_id, user_id) {
        let url = "/projects/close-project/" + project_id + "/" + user_id;
        fetch(url, {
                method: "post",
                body: JSON.stringify({
                    authenticity_token: AUTH_TOKEN
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
            })
            .then(response => response.json())
            .then(response => {
                this.setState(() => this.fetchOpenBids());
            })
    }

    render() {
        const sendQuoteBtnTooltip = (
            <Tooltip id="send-quote-tooltip">
                Only KitSplit Preferred Vendors can send quotes. 
            </Tooltip>
        );

        const notificationsTooltip = (
            <Tooltip id="send-quote-tooltip">
                <p>Only KitSplit Preferred Owners can recieve project notifications.</p> <a href="https://kitsplit.zendesk.com/hc/en-us/">Learn More.</a>
                <p>If you are a KitSplit Preferred Owner </p><a href="/users/sign_in">log in</a>.
            </Tooltip>
        );

        return (
            <div className="bid-request-container">
                <div className="bid-filters-notifications-container">
                    <div className="bid-filters">
                        <div className="dropdown">
                            <div className="dropdown-toggle search-filter__toggle cursor-pointer" id="owner-types-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <span className="filter-label">
                                    Location
                                </span>
                                <div className="bid-dropdown-options">
                                    {this.state.location} <span className="caret"></span>
                                </div>
                            </div>
                            <ul className="dropdown-menu search-filter" aria-labelledby="owner-types-dropdown">
                                <li onClick={() => this.setLocation("All")}>All</li>
                                <li onClick={() => this.setLocation("New York")}> New York</li>
                                <li onClick={() => this.setLocation("Los Angeles")}>Los Angeles</li>
                                <li onClick={() => this.setLocation("Atlanta")}>Atlanta</li>
                                <li onClick={() => this.setLocation("Other")}>Other</li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <div className="dropdown-toggle search-filter__toggle cursor-pointer" id="owner-types-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <span className="filter-label">
                                    Budget
                                </span>
                                <div className="bid-dropdown-options">
                                    {this.state.minBudget == 0 
                                        ? "All" 
                                        : "$" + this.state.minBudget} 
                                    <span className="caret"></span>
                                </div>
                            </div>
                            <ul className="dropdown-menu search-filter" aria-labelledby="owner-types-dropdown">
                                <li onClick={() => this.setBudget(0)}>All</li>
                                <li onClick={() => this.setBudget(500)}>> $500</li>
                                <li onClick={() => this.setBudget(1000)}>> $1000</li>
                                <li onClick={() => this.setBudget(2500)}>> $2500</li>
                                <li onClick={() => this.setBudget(5000)}>> $5000</li>
                            </ul>
                        </div>

                        <div className="dropdown">
                            <div className="dropdown-toggle search-filter__toggle cursor-pointer" id="owner-types-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <span className="filter-label">
                                    Sort By
                                </span>
                                <div className="bid-dropdown-options">
                                    {this.state.sortBy == "new"
                                        ? "Newest Projects" 
                                        : "Quotes Due Soon"} 
                                    <span className="caret"></span>
                                </div>
                            </div>
                            <ul className="dropdown-menu search-filter" aria-labelledby="owner-types-dropdown">
                                <li onClick={() => this.setSort('new')}>Newest Projects</li>
                                <li onClick={() => this.setSort('due-date')}>Quotes Due Soon</li>
                            </ul>
                        </div>
                    </div>
                    <div className="manage-bid-nofifcations">
                        <div className="filter-label">
                            Notifications
                            { this.props.preferredOwner ? 
                                <div className="bid-dropdown-options" onClick={() => this.showBidNotification(true)}>
                                    {this.state.userNotificationPreferences}
                                    <span className="caret"></span>
                                </div>
                            :
                                <div className="dropdown notifications-dropdown">
                                    <div className="dropdown-toggle search-filter__toggle cursor-pointer" id="owner-types-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        <div className="bid-dropdown-options">
                                            ALL <span className="caret"></span>
                                        </div>
                                    </div>
                                    <ul className="dropdown-menu search-filter" aria-labelledby="owner-types-dropdown">
                                        <li>
                                            <p>
                                                Only KitSplit Preferred Owners can recieve project notifications. <a href="https://kitsplit.zendesk.com/hc/en-us/">Learn More.</a>
                                            </p>
                                            <p>
                                            If you are a Preferred Owner please <a href="/users/sign_in">log in</a>.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <hr />
                <div className="all-requests-container row">
                    {this.state.projects.map((project, index) => {
                        return <div className="bid-request col-xs-12" key={index}>
                            <div className="bid-owner-container col-xs-12 col-sm-4">
                                <div>
                                    <h4>
                                        <b>{project.list_of_items.length} {project.list_of_items.length > 1 ? "Items" : "Item"}</b> for a {project.project_type} in {project.location}
                                    </h4>
                                    <p className="bid-posted-time">
                                        Posted {project.posted_at}
                                    </p>
                                    <div className="bid-user-info">
                                        Member since {project.member_since}
                                        <div className="bid-owner-rating">
                                            {project.ratings.count > 0
                                                ? <span className="ratings-count"><RatingStars rating={project.ratings.avg} />( {project.ratings.count} )</span>
                                                : <span>No Reviews</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="project-send-quote-container">
                                    { this.props.preferredOwner ? 
                                        <a href={"/projects/build-quote/" + this.props.user_id + "?bid_id=" + project.id}>
                                            <button className="sk-button">
                                                Send quote
                                            </button>
                                        </a>
                                    :
                                        <OverlayTrigger placement="bottom" overlay={sendQuoteBtnTooltip}>
                                            <button className="sk-button">
                                                Send Quote
                                            </button>
                                        </OverlayTrigger>
                                    }
                                    <div className="deadline">Quote Deadline: { moment(project.bid_deadline).utc().format('MMM Do') }</div>
                                    { project.current_user_is_renter_or_admin == true &&
                                        <div className="close-project" onClick={() => this.deleteProject(project.id, this.props.user_id)}>
                                            Close This Project
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="gear-list-container  col-xs-12 col-sm-4">
                                <p className="gear-list-heading">
                                    GEAR LIST
                                </p>
                                <div className="item-list">
                                    {project.list_of_items.map((item, index) => 	
                                        <p key={index}>{item}</p>	
                                    )}
                                </div>
                            </div>
                            <div className="bid-pickup-details col-xs-12 col-sm-4">
                                <div className="col-xs-6">
                                    <p className="gear-list-heading">
                                        PICKUP
                                    </p>
                                    <p className="bid-dates">
                                        { moment(project.start_date).utc().format('ddd, MMMM Do') }
                                    </p>
                                    <p className="gear-list-heading">
                                        EST. BUDGET
                                    </p>
                                    <p>
                                        ${ parseFloat(project.budget).toFixed(2) }
                                    </p>
                                </div>
                                <div className="bid-pickup-details col-xs-6">
                                    <p className="gear-list-heading">
                                        DROPOFF
                                    </p>
                                    <p className="bid-dates">
                                        { moment(project.end_date).utc().format('ddd, MMMM Do') }
                                    </p>
                                </div>
                            </div>
                        </div>;
                    })}
                </div>
                <BidNotificationPreferencesModal 
                    visible={this.state.bidNotificationModal}
                    onClose={() => this.showBidNotification(false)} 
                    userNotificationPreferences={this.state.userNotificationPreferences}
                    setNotification={this.setNotification}
                    deleteProject={this.deleteProject}
                    changeNotificationPreferences={this.changeNotificationPreferences}/>
            </div>
        )
    }
}

export default ProjectsContainer