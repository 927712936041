import React from "react"
import PropTypes from "prop-types"
import RatingStars from "./RatingStars"
import ListingRequestInfoModal from "./ListingRequestInfoModal"

class ListingRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      price_per_day: this.props.price_per_day,
      listing_id: this.props.listing_id,
      listing_name: this.props.listing_name,
      listing_href: Routes.listing_path(this.props.listing_id),
      listing_btn_img_url: this.props.listing_btn_img_url,
      listing_img_alt_text: this.props.listing_img_alt_text,
      listing_incomplete: this.props.listing_incomplete,
      listing_enabled: this.props.listing_enabled,
      gear_owner_id: this.props.gear_owner_id,
      gear_owner_profile_href: Routes.profile_path(this.props.gear_owner_id),
      gear_owner_avatar: this.props.gear_owner_avatar,
      gear_owner_name: this.props.gear_owner_name,
      gear_owner_rating: this.props.gear_owner_rating,
      modalTrigger: this.props.modalTrigger,
      mapTrigger: this.props.mapTrigger
    }
  }

  

  render() {
    return (
      <li className="listing-request" data-id={this.state.listing_id}>
        <div className="row">
          <div className="col-md-1 col-xs-2 no-padding">
            <label className="control control--checkbox">
              <input type="checkbox" id="request_listing"></input>
              <div className="control__indicator"></div>
            </label>
          </div>

          <div className="col-md-10 col-xs-9 no-padding">
            <div className="row">
              <div className="col-md-2 cart-image no-padding">
                <a href="#" className="listing-request-img-link" onClick={ this.state.modalTrigger }>
                  <img src={this.state.listing_btn_img_url}></img>
                </a>
              </div>

          <div className="col-md-10 col-xs-12 cart-info">
            <div className="row">

              <div className="col-md-8 col-xs-8 no-padding" style={{marginTop: '0.6rem'}}>
                <h4 className="listing-name cart-listing-link truncate-with-ellipsis">
                  <a href="#" onClick={ this.state.modalTrigger }>
                    { this.state.listing_name }
                  </a>
                </h4>

                <div className="listing-price-per-day">
                  <h5>
                    <b>{ this.state.price_per_day }</b> per day
                  </h5>
                </div>
              </div>

              <div className="col-md-4 col-xs-4 no-padding" style={{marginTop: '0.6rem'}}>
                <div className="row" style={{ marginTop: '1rem' }}>
                  <div className="col-md-5">
                    <div className="user-avatar pull-left">
                      <a href={this.state.gear_owner_profile_href} target="_blank">
                        <img className="listing-owner-img" src={ this.state.gear_owner_avatar } alt={this.state.gear_owner_name}></img>
                      </a>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="user-info">
                      <p className="listing-owner-name">
                        <a href={this.state.gear_owner_profile_href} target="_blank">
                          {this.state.gear_owner_name}
                        </a>
                      </p>

                      <div className="listing-owner-rating">
                        <RatingStars rating={this.state.gear_owner_rating} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      
    </div>
    </li>
    )
  }

}

export default ListingRequest
