import React from 'react'
import PropTypes from 'prop-types'

class ProvideCoi extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="insurance-option-details">
				<div>
					<p className="insurance-section-heading">Provide your own insurance policy</p>
					<button className="sk-link-button change-coverage-link" onClick={this.props.changeInsuranceOption}>CHANGE COVERAGE TYPE</button>
				</div>
				<hr />
				<p>
					If your rental is accepted, you'll receive an email asking you to <strong>upload a COI</strong> issued from your insurance broker with the owner identified as the loss payee.
				</p>
				<p className="insurance-hold-details">
					You must upload a COI before your rental starts or you will be charged <strong><i>${ parseInt(this.props.individualRentalPremium).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }</i></strong> for short-term damage coverage. You may see a temporary hold on your card for this amount a few days before the rental starts.
				</p>
				<button className="btn sk-button--size-lg" onClick={this.props.submitProductionInsuranceForm}>I AGREE</button>
			</div>
		)
	}

}

export default ProvideCoi