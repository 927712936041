import React from 'react'
import PropTypes from 'prop-types'
import IndividualInsuranceForm from './IndividualInsuranceForm'
import IndividualInsuranceTerms from './IndividualInsuranceTerms'
// rdday cleanup
// import IndividualInsuranceUnderwriting from './IndividualInsuranceUnderwriting'
import InsuranceSelectCoverage from './InsuranceSelectCoverage'

class IndividualInsurance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 1
		}
		this.nextStep = this.nextStep.bind(this)
		this.previousStep = this.previousStep.bind(this)
	}

	showFormStep() {
		switch (this.state.step) {
			case 1:
				return <IndividualInsuranceForm
								nextStep={this.nextStep}
								handleFormChange={this.props.handleFormChange}
								accountTypeForm={this.props.accountTypeForm}
								firstNameForm={this.props.firstNameForm}
								lastNameForm={this.props.lastNameForm}
								address1Form={this.props.address1Form}
								address2Form={this.props.address2Form}
								zipCodeForm={this.props.zipCodeForm}
								cityForm={this.props.cityForm}
								stateForm={this.props.stateForm}
								phoneNumberForm={this.props.phoneNumberForm}
								birthdayForm={this.props.birthdayForm}
								/>
			case 2:
				return <InsuranceSelectCoverage
									internationalDeductibleSelected={this.props.internationalDeductibleSelected}
									internationalDeductibleOption={this.props.internationalDeductibleOption}
									theftFromAnyVehicleDeductibleSelected={this.props.theftFromAnyVehicleDeductibleSelected}
									theftFromAnyVehicleOption={this.props.theftFromAnyVehicleOption}
									theftFromUnlockedVehicleSelected={this.props.theftFromUnlockedVehicleSelected}
									handleCheckbox={this.props.handleCheckbox}
									nextStep={this.nextStep}
									previousStep={this.previousStep}
									submitIndividualInsuranceForm={this.props.submitIndividualInsuranceForm}
									handleFormChange={this.props.handleFormChange}
									updateIndividualInsuranceCost={this.props.updateIndividualInsuranceCost}
								/>
			case 3:
				return <IndividualInsuranceTerms
								nextStep={this.nextStep}
								previousStep={this.previousStep}
								handleCheckbox={this.props.handleCheckbox}
								agreeToTerms={this.props.agreeToTerms}
								handleFormChange={this.props.handleFormChange}
								insuranceCarrier={this.props.insuranceCarrier}
								policyStartDate={this.props.policyStartDate}
								policyEndDate={this.props.policyEndDate}
								dateOfLoss={this.props.dateOfLoss}
								itemsInClaim={this.props.itemsInClaim}
								claimCircumstances={this.props.claimCircumstances}
								totalClaimPayout={this.props.totalClaimPayout}
								policyNumber={this.props.policyNumber}
								submitIndividualInsuranceForm={this.props.submitIndividualInsuranceForm}
								/>
		}
	}

	nextStep() {
		this.setState({
			step: this.state.step + 1
		})
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	previousStep() {
		this.setState({
			step: this.state.step - 1
		})
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	render() {
		var circles = []
		for(var i = 1; i < 4; i++) {
			circles.push(<span className={i == this.state.step ? "step active" : "step"} key={i}></span>)
		}

		return (
			<div>
				{ this.props.existingPolicyId != "" ? 
					<div>
						<p>
							You already have an existing policy covering <strong>${parseFloat(this.props.existingPolicyReplacementValue).toLocaleString()}</strong> worth of gear. Because you are renting additional gear during this time, we will increase coverage of your policy.
						</p>
						<button type="button" className="sk-button--size-lg next" onClick={this.props.submitIndividualInsuranceForm}>Add Onto Your Existing Short-Term Insurance Policy</button>
					</div>
					: 
					<div>
						{this.showFormStep()}
						<div className="steps">
							{circles}
						</div>
					</div>
				}
			</div>
		)
	}
}

export default IndividualInsurance
