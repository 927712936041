{ 
	this.props.userHeardAboutKitsplit != "" ? 
	""
	:
	<div className="heard-about-kitsplit">
		<form>
			<label className="sk-form__input-wrapper--half">
				<span className="sk-form__input-label">
					*How did you hear about Kitsplit?
				</span>
				<div className="sk-form__select-wrapper">
					<select name="heardAboutKitsplit" className="sk-form__select" value={this.state.heardAboutKitsplit} onChange={this.handleFormChange}>
						<option default>Select an option</option>
						<option value="friend_colleague">Friend/colleague</option>
						<option value="organization">An organization I'm a part of</option>
						<option value="rental_house_referral">Rental house referral</option>
						<option value="google_search">Google search</option>
						<option value="facebook">Facebook</option>
						<option value="instagram">Instagram</option>
						<option value="twitter">Twitter</option>
						<option value="billboard_sign">Billboard/sign</option>
						<option value="event">Event</option>
						<option value="news_article">News/article</option>
						<option value="email_or_call_from_kitsplit">Email/call from Kitsplit</option>
						<option value="mailing_postcards">Mailing/postcards</option>
            <option value="fma">Free Music Archive site or social media</option>
						<option value="other">Other</option>
					</select>
				</div>
				{this.state.heardAboutKitsplit == "other" ?
					<div className="sk-form__input-wrapper">
						<span className="sk-form__input-label">
							Let us know how you heard about Kitsplit
						</span>
						<input type="text" className="sk-form__text-input form-control" name="heardAboutKitsplitOther" value={this.state.heardAboutKitsplitOther} onChange={this.handleFormChange} />
					</div>
				:
					<div>
					</div>
				}
			</label>
		</form>
	</div>
}