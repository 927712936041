import React from "react";
import PropTypes from "prop-types";
import yup from "yup";

class ConciergeStep1 extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      gearNeededCount: this.props.gearNeeded.length,
      showErrorMessage: false
    }
    this.keyPress = this.keyPress.bind(this);
    this.determineNextStep = this.determineNextStep.bind(this);
  }  
  componentDidMount() {  
    this.initConciergeAutocomplete();
  }
  componentDidUpdate() {    
    if(this.props.gearNeeded.length > this.state.gearNeededCount) {
      this.updateGearNeededCount();
      this.initConciergeAutocomplete();
    }
  }
  updateGearNeededCount() {
    this.setState({
      gearNeededCount: this.props.gearNeeded.length
    })
  }
  initConciergeAutocomplete() {
    bindTypeaheadForConciergeForm();    
  }
  keyPress(e){
    if(e.keyCode == 13){
      e.target.blur();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();        
    }
  }

  determineNextStep() {
    let count = 0;
    let searchStrings = [];

    if(this.props.showListView) {
      for (let obj in this.props.gearNeeded) {
        if (this.props.gearNeeded[obj].item != "" && this.props.gearNeeded[obj].item != undefined) {
          count++;
          searchStrings.push(this.props.gearNeeded[obj].item.toString());
        }
      }

      if(count > 3) {
        this.props.nextStep();
      } else if (count < 1) {
        this.setState({
          showErrorMessage: true
        })
      } else {
        // build search link

        var loc = '/search?';
        var arrayLength = searchStrings.length;
        for (var i = 0; i < arrayLength; i++) {
          console.log(searchStrings[i]);
          loc += 'filters%5B' + i + '%5D%5Bquery%5D=' + searchStrings[i] + '&';
        }

        window.location.href = loc;          
      }
    } else {
      this.props.nextStep();
    }

  }

	render() {
		return (
            <div>
              <p className="home-form-header">What do you need to rent?</p>
              <p className="home-form-subheader">
                Submit your entire gear list, and we'll find everything you need.
              </p>
              <span className="sk-form__input-label">
                ITEMS
              </span>
              { this.state.showErrorMessage ? 
                <span className="concierge-warning">
                  Please enter an item
                </span>
                : 
                ""
              }
              <div>
                { this.props.showListView ? 
                  <div>
                    <label className="sk-form__input-wrapper concierge-input bloodhound">
                      <div>
                        {
                          this.props.gearNeeded.map((gear, idx) => <input type="text" className="sk-form__text-input form-control js-concierge-search-input search_input-autocomplete" ref={gear.ref} name="gearNeeded" key={idx} data-key={idx} placeholder={gear.placeholder ? gear.placeholder : "Add additional item"} value={gear.item} onChange={this.props.handleGearListChange} onBlur={this.props.handleGearListChange} onKeyDown={this.keyPress} />)
                        }
                      </div>            
                    </label>
                    <button type="button" className="sk-link-button" onClick={this.props.addGear}>+ Add Item</button>
                  </div>
                  :
                  <div>
                    <label htmlFor="gearNeeded" className="sk-form__input-wrapper">
                      <textarea className="sk-form__textarea form-control concierge-text-area" name="gearNeededList" rows="5" placeholder="- 1x Arri Alexa Mini&#10;- 1x Set of Sigma Cine Primes (24, 35, 50, 85)&#10;- Small Lighting & Grip package TBD&#10;- audio package w/ boom, lav, and recorder" value={this.props.gearNeededList} onChange={this.props.handleChange} ></textarea>
                    </label>
                  </div>
                }
              </div>
              <div className="switch-form-link">
                <a href="#" className="sk-link-button--color-white" onClick={this.props.switchFormInputType}>
                  { this.props.showListView ? "Long List?" : "Single Items" }
                </a>
              </div>
              <div className="form-navigation">
                <button type="button" className="sk-button--size-lg next" onClick={this.determineNextStep}>Get Started</button>
              </div>
            </div>
		)
	}
}

export default ConciergeStep1