import React from "react"
import PropTypes from "prop-types"
import yup from "yup";

class ProjectDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {errors: {}};

		this.validate = this.validate.bind(this);
	}

	validate() {
		var schema = yup.object().shape({
			location: yup.string().required("Please enter a location"),
			pickupDate: yup.string()
				.matches(/2[0-9]{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/, "Please enter a valid date")
				.required("Please enter a valid date"),
			dropOffDate: yup.string()
				.matches(/2[0-9]{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/, "Please enter a valid date")
				.required("Please enter a valid date"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.nextStep();
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	render() {
		return (
			<div>
		    <label htmlFor="location" className="sk-form__input-wrapper">
		      <span className="sk-form__input-label--size-lg">
		        Pick Up Location
		      </span>
					{this.state.errors.location && <span className="concierge-warning">{this.state.errors.location}</span>}
		      <input type="text" className="sk-form__text-input form-control" placeholder="Brooklyn, Los Angeles..." name="location" value={this.props.location} onChange={this.props.handleChange} />
		    </label>
		    
			<label className="sk-form__input-wrapper">
				<span className="sk-form__input-label--size-lg">
		        	Pick Up Date
		      	</span>
				{this.state.errors.pickupDate && <span className="concierge-warning">{this.state.errors.pickupDate}</span>}
		      	<input type="date" className="sk-form__text-input form-control" name="pickupDate" value={this.props.pickupDate} onChange={this.props.handleChange} />
			</label>
			<label className="sk-form__input-wrapper">
				<span className="sk-form__input-label--size-lg">
		        	Dropoff Date
		      	</span>
				{this.state.errors.dropOffDate && <span className="concierge-warning">{this.state.errors.dropOffDate}</span>}
			  	<input type="date" className="sk-form__text-input form-control" name="dropOffDate" value={this.props.dropOffDate} onChange={this.props.handleChange} />
			</label>
		    <div className="form-navigation">
		    	<button type="button" className="sk-link-button--color-edu previous" onClick={this.props.previousStep}>&lt; Back</button>
		    	<button type="button" className="sk-button--color-edu--size-lg next" onClick={this.validate}>Continue</button>
		    </div>
	    </div>
		)
	}
}

export default ProjectDetails
