import React from 'react'
import PropTypes from 'prop-types'

class DamageWaiver extends React.Component {
	render() {
		return(
			<div>
				<p>
					A charge of <strong>${parseFloat(this.props.damageWaiverEstimate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</strong> will be added to your total.
				</p>

				<p style={{fontSize: '1.1em'}}>
					<strong>I certify that everything I entered in my KitSplit profile is accurate. I understand that if I entered anything inaccurately, or misrepresented myself or my company I will be denied coverage and <u>will be responsible for any damage or loss</u>. By clicking this link I agree to terms of the damage waiver and deductible as described on our <a href="https://kitsplit.com/short-term-insurance" target="_blank" className="sk-link-button">insurance</a> page.</strong>
				</p>
				<button className="btn sk-button--size-lg" onClick={this.props.submitIndividualDamageWaiverForm}>PURCHASE</button>
			</div>
		)
	}
}

export default DamageWaiver
