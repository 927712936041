import React from 'react'
import PropType from 'prop-types'
import ConciergeStep1 from './HomepageConcierge/ConciergeStep1'
import ConciergeStep2 from './HomepageConcierge/ConciergeStep2'
import ConciergeStep3 from './HomepageConcierge/ConciergeStep3'
import ConciergeStep4 from './HomepageConcierge/ConciergeStep4'
import ConciergeStep5 from './HomepageConcierge/ConciergeStep5'
import HomepageConciergeComplete from './HomepageConcierge/HomepageConciergeComplete'

class HomepageConciergeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			userId: this.props.userId,
			step: 1,
            gearNeeded: [
              {item: this.props.formProduct == "" ? "" : this.props.formProduct, placeholder: "Arri Alexa Mini"},
              {item: "", placeholder: "Set of Sigma Prime Lenses(24, 35, 50, 85)"},
              {item: "", placeholder: "Small Lighting & Grip package"}
            ],            
            gearNeededList: "",
			location: this.props.location == "" ? "" : this.props.location,
            pickUpDate: "",
            dropOffDate: "",
            firstName: this.props.userFirstName,
            lastName: this.props.userLastName,
			email: this.props.email,
            phoneNumber: this.props.phoneNumber,
            showListView: true,
		};

        this.handleChange = this.handleChange.bind(this);
        this.handleGearListChange = this.handleGearListChange.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.previousStep = this.previousStep.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.addGear = this.addGear.bind(this);
        this.switchFormInputType = this.switchFormInputType.bind(this);
    }

    handleChange(event) {
  		this.setState({
  			[event.target.name] : event.target.value
  		})
    }

    handleGearListChange(event) {
        const target = event.target;
        this.state.gearNeeded[target.dataset.key].item = target.value;
        this.setState({gearNeeded: this.state.gearNeeded})
    }
    
    addGear(event) {
      event.preventDefault();
      this.state.gearNeeded.push({item: ""})
      this.setState({gearNeeded: this.state.gearNeeded})
    }

    switchFormInputType() {
        this.setState({
            showListView: !this.state.showListView,
        })
    }

	nextStep() {
    if(this.state.step == 1) {
      $.ajax({
        url: "/homepage-concierge-ab-test/",
        type: 'POST',
        dataType: 'json',
        data: {ab_version: this.props.ab_version},
        success: function (response) {
        }
      });
    }

		this.setState({
			step: this.state.step + 1
		})
	}

	previousStep() {
		this.setState({
			step: this.state.step - 1
		})
  }
    
  handleSubmitForm(e) {
		e.preventDefault();
		var data = {
			"gearNeeded": this.state.showListView == true ? this.state.gearNeeded : this.state.gearNeededList,
			"location": this.state.location,
            "pickUpDate": this.state.pickUpDate,
            "dropOffDate": this.state.dropOffDate,
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
			"email": this.state.email,
			"phoneNumber": this.state.phoneNumber,
            "userId": this.state.userId
		}
        
		$.ajax({
            url: "/concierge_request",
            type: 'POST',
            dataType: 'json',
            data: data,

            success: function (response) {
                event.preventDefault();
                this.setState({
                            step: 4
                        })
            }.bind(this),
            error: function (response) {

            }
        });
    }
    
    showStep() {
		switch (this.state.step) {
			case 1:
				return <ConciergeStep1
                            handleChange={this.handleChange}
                            handleGearListChange={this.handleGearListChange}
                            showListView={this.state.showListView}
                            switchFormInputType={this.switchFormInputType}
                            nextStep={this.nextStep}
                            gearNeeded={this.state.gearNeeded}
                            gearNeededList={this.state.gearNeededList}
                            addGear={this.addGear}
                            ab_version={this.props.ab_version} />
			case 2:
				return <ConciergeStep2
                            handleChange={this.handleChange}
                            nextStep={this.nextStep}
                            previousStep={this.previousStep}
                            location={this.state.location}
                            pickUpDate={this.state.pickUpDate}
                            dropOffDate={this.state.dropOffDate} />
			case 3:
				return <ConciergeStep3
                            handleChange={this.handleChange}
                            previousStep={this.previousStep}
                            nextStep={this.nextStep}
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            phoneNumber={this.state.phoneNumber}
                            email={this.state.email}
                            handleSubmitForm={this.handleSubmitForm}
                            reCaptchaSiteKey={this.props.recaptcha_key}
                            userId={this.state.userId}
                            handleSubmitForm={this.handleSubmitForm}
                            reCaptchaSiteKey={this.props.recaptcha_key} />
			case 4:
				return <HomepageConciergeComplete
                            userId={this.state.userId}
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            phoneNumber={this.state.phoneNumber}
                            email={this.state.email} />
		}
	}

    render() {
        var circles = []
		for(var i = 1; i < 4; i++) {
			circles.push(<span className={i == this.state.step ? "step active" : "step"} key={i}></span>)
        }
        
        return (
            <div>
                <form id="homepage-concierge-form">
                    {this.showStep()}

                    <div className="steps">
                        {this.state.step == 4 ? "" : circles}
                    </div>
                </form>
            </div>
        );
    }
}

export default HomepageConciergeForm