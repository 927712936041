import React from "react"
import PropTypes from "prop-types"
import yup from "yup";
import Recaptcha from "react-recaptcha";

class ConciergeStep4 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
		};
	};

	validate() {
		var schema = yup.object().shape({
			phoneNumber: yup.string()
				.matches(/[0-9]{3}-?[0-9]{3}-?[0-9]{4}/, "Please enter a valid phone number")
				.required("Please enter a valid phone number"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.nextStep();
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	render() {
		const context = this;
		return (
			<div>
                <label className="sk-form__input-wrapper" htmlFor="phoneNumber">
                    <span className="sk-form__input-label--size-lg">
                        Phone Number
                    </span>
                    {this.state.errors.phoneNumber && <span className="concierge-warning">{this.state.errors.phoneNumber}</span>}
                    <input type="text" className="sk-form__text-input form-control" name="phoneNumber" placeholder="555-555-5555" value={this.props.phoneNumber} onChange={this.props.handleChange} />
                </label>
                <label htmlFor="projectType" className="sk-form__input-wrapper">
                    <span className="sk-form__input-label--size-lg">
                        What type of project are you planning?
                    </span>
                    <div className="sk-form__select-wrapper">
                        <select name="projectType" className="sk-form__select project-select" value={this.props.projectType} onChange={this.props.handleChange}>
                        <option value="feature film">Feature Film</option>
                        <option value="documentary">Documentary</option>
                        <option value="scripted tv show">Scripted TV Show</option>
                        <option value="reality tv show">Reality TV Show</option>
                        <option value="live event/wedding">Live Event / Wedding</option>
                        <option value="promotional video">Promotional Video</option>
                        <option value="web series / short form">Web Series / Short Form</option>
                        <option value="music video">Music Video</option>
                        <option value="commercial">Commercial</option>
                        <option value="branded content">Branded Content</option>
                        <option value="social media content">Social Media Content</option>
                        <option value="photoshoot">Photo Shoot</option>
                        <option value="other">Other</option>
                        </select>
                    </div>
                </label>
                <div className="form-navigation">
                    <button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
                    <button type="button" className="sk-button--size-lg next" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </div>
		)
	}
};

export default ConciergeStep4
