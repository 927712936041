import React from "react"
import PropTypes from "prop-types"

import { Modal, Button } from "react-bootstrap"

class ChangeDateModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    setTimeout(bindCalendarEvents, 1000)
  }

  handleClose() {
    this.props.onClose();
  }

  render () {
    let options = [];
    for (let i=0; i < 24; i++) {
      options.push(<option value={i} key={i}>{i}</option>);
    }

    return (
      <Modal show={this.props.visible} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Dates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calendar-input-container">
            <div className="row">
              <div className="form-group col-xs-8">
                <label className="sk-form__input-wrapper">
                  <div id="listing_start_date" className="input-group date datepicker">
                    <input id='start_date' name="startDate" type="text" className="start_date form-control make-datepicker ks-input sk-form__text-input" placeholder="MM-DD-YYYY" autoComplete="off" value={moment(Date.parse(this.props.startDate)).format("MM-DD-YYYY")} onChange={this.props.handleFormChange} />
                    <span className="input-group-addon">
                      <span className="glyphicon glyphicon-calendar"></span>
                    </span>
                  </div>
                  <span className="sk-form__input-label">
                    PICKUP DATE
                  </span>

                </label>
              </div>
              <div className="col-xs-4 calendar-time-row">
                <label htmlFor="listingPickupTime" className="sk-form__input-wrapper">
                  <select id="start_time" name="startTime" onChange={this.props.handleFormChange}>
                    <option value="00">12 AM</option>
                    <option value="01">01 AM</option>
                    <option value="02">02 AM</option>
                    <option value="03">03 AM</option>
                    <option value="04">04 AM</option>
                    <option value="05">05 AM</option>
                    <option value="06">06 AM</option>
                    <option value="07">07 AM</option>
                    <option value="08">08 AM</option>
                    <option value="09">09 AM</option>
                    <option value="10">10 AM</option>
                    <option value="11">11 AM</option>
                    <option value="12">12 PM</option>
                    <option value="13">01 PM</option>
                    <option value="14">02 PM</option>
                    <option value="15">03 PM</option>
                    <option value="16">04 PM</option>
                    <option value="17" selected="selected">05 PM</option>
                    <option value="18">06 PM</option>
                    <option value="19">07 PM</option>
                    <option value="20">08 PM</option>
                    <option value="21">09 PM</option>
                    <option value="22">10 PM</option>
                    <option value="23">11 PM</option>
                  </select>
                  <span className="sk-form__input-label">
                    TIME
                    <span className="glyphicon glyphicon-question-sign" style={{marginLeft:".7em"}} data-toggle="tooltip" data-placement="bottom" title="You are allowed to pickup the gear anytime after 5pm before your first full day rental."></span>
                  </span>
                </label>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-xs-8">
                <label className="sk-form__input-wrapper">
                  <div id="listing_end_date" className="input-group date datepicker">
                    <input id='end_date' name="endDate" type="text" className="end_date form-control make-datepicker ks-input sk-form__text-input" placeholder="MM-DD-YYYY" autoComplete="off" value={moment(Date.parse(this.props.endDate)).format("MM-DD-YYYY")}  onChange={this.props.handleFormChange}/>
                    <span className="input-group-addon">
                      <span className="glyphicon glyphicon-calendar"></span>
                    </span>
                  </div>
                  <span className="sk-form__input-label">
                    RETURN DATE
                  </span>
                </label>
                <button className="sk-button--size-lg apply-discount-btn" onClick={this.props.changeCartDates}>UPDATE</button>
              </div>
              <div className="col-xs-4 calendar-time-row">
                <label htmlFor="listingReturnTime" className="sk-form__input-wrapper">
                  <select id="end_time" name="endTime" onChange={this.props.handleFormChange}>
                    <option value="00">12 AM</option>
                    <option value="01">01 AM</option>
                    <option value="02">02 AM</option>
                    <option value="03">03 AM</option>
                    <option value="04">04 AM</option>
                    <option value="05">05 AM</option>
                    <option value="06">06 AM</option>
                    <option value="07">07 AM</option>
                    <option value="08">08 AM</option>
                    <option value="09">09 AM</option>
                    <option value="10" selected="selected">10 AM</option>
                    <option value="11">11 AM</option>
                    <option value="12">12 PM</option>
                    <option value="13">01 PM</option>
                    <option value="14">02 PM</option>
                    <option value="15">03 PM</option>
                    <option value="16">04 PM</option>
                    <option value="17">05 PM</option>
                    <option value="18">06 PM</option>
                    <option value="19">07 PM</option>
                    <option value="20">08 PM</option>
                    <option value="21">09 PM</option>
                    <option value="22">10 PM</option>
                    <option value="23">11 PM</option>
                  </select>
                  <span className="sk-form__input-label">
                    TIME
                    <span className="glyphicon glyphicon-question-sign" style={{marginLeft: ".7em"}} data-toggle="tooltip" data-placement="bottom" title="You are allowed to drop off the gear before 10AM the day following a full day rental."></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangeDateModal
