import React from 'react'
import PropTypes from 'prop-types'
import CourseListingsSummary from './CourseCheckout/CourseListingsSummary'
import CourseConversationsPriceSummary from './CourseCheckout/CourseConversationsPriceSummary'
import CourseCheckoutPayment from './CourseCheckout/CourseCheckoutPayment'
import CourseCheckoutPaymentComplete from './CourseCheckout/CourseCheckoutPaymentComplete'
import CourseCheckoutCreateUser from './CourseCheckout/CourseCheckoutCreateUser'

import { OverlayTrigger, Tooltip } from "react-bootstrap"

class CourseCheckoutContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signUpEmail: "",
			firstNameForm: "",
			lastNameForm: "",
			creditCardBrand: this.props.selectedCardBrand,
			creditCardLast4: this.props.selectedCardLast4,
			creditCardExpiration: this.props.selectedCardExpiration,
			paymentCompleted: this.props.validPayment,
			paymentCardSelected: "",
			creditCardId: "",
			creditAmount: this.props.creditAmountAvailable,
			discountErrors: "",
			couponCode: "",
			couponPercent: "",
			couponAmount: this.props.couponAmount,
			discountErrors: "",
			discountTypeApplied: "",
			checkoutTotal: this.props.checkoutTotal,
			creditAmountApplied: this.props.referralCreditApplied,
      		heardAboutKitsplitAtLoad: (this.props.userHeardAboutKitsplit == "" || this.props.userHeardAboutKitsplit == null) ? "" : this.props.userHeardAboutKitsplit,
			heardAboutKitsplit: (this.props.userHeardAboutKitsplit == "" || this.props.userHeardAboutKitsplit == null) ? "Select an option" : this.props.userHeardAboutKitsplit,
			heardAboutKitsplitOther: this.props.heardAboutKitsplitOther,
			validEmail: true,
			setDefaultCard: !this.props.validPayment
		}

		this.resetPaymentCompleted = this.resetPaymentCompleted.bind(this);
		this.paymentCompleted = this.paymentCompleted.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.validateCouponCode = this.validateCouponCode.bind(this);
		this.applyCouponToConversationsInCart = this.applyCouponToConversationsInCart.bind(this);
		this.resetDiscountApplied = this.resetDiscountApplied.bind(this);
		this.applyReferralCreditCourse = this.applyReferralCreditCourse.bind(this);
		this.submitCourseCheckout = this.submitCourseCheckout.bind(this);
    this.checkUniqueEmailForClassGuestCheckout = this.checkUniqueEmailForClassGuestCheckout.bind(this);
	}

	resetPaymentCompleted() {
		this.setState({
			paymentCompleted: false,
			creditCardBrand: "",
			creditCardLast4: "",
			creditCardExpiration: ""
		})
	}

	paymentCompleted(creditCardBrand, creditCardLast4, creditCardExpiration) {
		this.setState({
			paymentCompleted: true,
			setDefaultCard: false,
			creditCardBrand: creditCardBrand,
			creditCardLast4: creditCardLast4,
			creditCardExpiration: creditCardExpiration
		})
	}

	handleFormChange(event) {
		this.setState({
			[event.target.name] : event.target.value
		})
	}

	submitCourseCheckout(e) {    
		e.preventDefault();
		const url = '/book-lesson';
		e.target.setAttribute('disabled', true);
		$('#course-book-now-btn').removeClass('sk-button--size-xlg').addClass('disabled sk-button--size-xlg--loading');
		
		// leave out onboarding for now
		// if(this.state.paymentCompleted) {
			if(this.state.discountTypeApplied == "coupon") {
				// const coupon = this.state.couponCode;
    //     const coupon_url = "/calculate-coupon-for-course-checkout/" + coupon;
    //     const data = {save: "true"}
    //     data["conversation_id"] = this.props.conversation_id;

    //     $.ajax({
    //       type: 'PATCH',
    //       url: coupon_url,
    //       data: data,
    //       dataType: 'json',
    //       success: function (res) {
    //         if (res.coupon_amt !== 0) {
    //           // Good coupon. Update exchange visuals
    //               this.setState({
    //                 discountTypeApplied: "coupon",
    //                 discountErrors: ""
    //               })
    //         } else {
    //           // Bad Coupon. Display warning message
    //               this.setState({
    //                 discountErrors: "Discount not applied, check coupon and try again."
    //               })
    //         }
    //       }.bind(this),
    //     });
			}

			$.ajax({
			    type: 'PATCH',
			    data: {
  					conversation_id: this.props.conversation_id,
            coupon: this.state.couponCode,
            save: "true",
  					idem_key: this.props.idem_key,
  					user: {
  						email: this.state.signUpEmail,
  						first_name: this.state.firstNameForm,
  						last_name: this.state.lastNameForm,
  						heard_about_kitsplit_channel: this.state.heardAboutKitsplit,
  						heard_about_kitsplit_channel_other: this.state.heardAboutKitsplitOther
  					}
			    },
			    url: url,
			    dataType: 'json',
			    success: function(res) {
			      if(res.success == true) {
			      	window.location.href = res.path;
			      } else if(res.success==false){
              alert('This email address exists. Please log in.');
            } else {
              alert('Sorry, there was an error, please contact KitSplit.');
			        // error
			      }
			    }.bind(this)
			  });
		// }
	}

	validateCouponCode() {
		const coupon = this.state.couponCode;
		const url = "/calculate-coupon-for-course-checkout/" + coupon;
		const data = {save: "false"}
		data["conversation_id"] = this.props.conversation_id;

		$.ajax({
			type: 'PATCH',
			url: url,
			dataType: 'json',
			data: data,
			success: function (res) {
				if (res.coupon_amt !== 0) {
					// Good coupon. Update exchange visuals
					this.setState({
						discountTypeApplied: "coupon",
						discountErrors: "",
						couponPercent: res.coupon_percent_off,
						couponAmount: res.coupon_amt,
						checkoutTotal: this.state.checkoutTotal - res.coupon_amt
					})
				} else {
					// Bad Coupon. Display warning message
					this.setState({
						discountErrors: "Discount not applied, please make sure you have entered it correctly."
					})
				}
			}.bind(this),
			error: function(res) {
				// Bad Coupon. Display warning message
			}
		});
	}

	applyCouponToConversationsInCart() {
    // TODO this function wasn't getting called for some reason, but it's only used in 1 place so we copy/pasted this code
    // into that block

		// const coupon = this.state.couponCode;
		// const url = "/apply-coupon-to-course-conversation/" + coupon;
		// const data = {}
		// data["conversation_id"] = this.props.conversation_id;

		// $.ajax({
		//   type: 'PATCH',
		//   url: url,
		//   data: data,
		//   dataType: 'json',
		//   success: function (res) {
  // 			if (res.coupon_amt !== 0) {
  // 			  // Good coupon. Update exchange visuals
  // 					  this.setState({
  // 						  discountTypeApplied: "coupon",
  // 						  discountErrors: ""
  // 					  })
  // 			} else {
  // 			  // Bad Coupon. Display warning message
  // 					  this.setState({
  // 						  discountErrors: "Discount not applied, check coupon and try again."
  // 					  })
  // 			}
		//   }.bind(this),
		// });
	}

	applyReferralCreditCourse() {
		const creditAmt = this.state.creditAmountAvailable;
		const creditAmtApplied = this.state.creditAmountApplied;
		const courseConversationId = this.props.conversation_id;
		const data = {}
		const url_path = '/apply-referral-credit-to-course-conversation'

		if (creditAmtApplied > creditAmt) {
		  this.setState({
				  discountErrors: "The amount you entered is more than you have available."
			  })
		} else if (creditAmtApplied <= 0) {
			  this.setState({
			   discountErrors: "Please enter an amount greater than 0."
		   })
		} else {
		  data["credit_amt"] = creditAmtApplied
		  data["id"] = courseConversationId
		  $.ajax({
			type: 'PATCH',
			url: url_path,
			dataType: 'json',
			data: data,
			success: function (res) {
			  if (res.credit_amount_applied !== 0) {
				// Credit was applied. Update exchange visuals
				this.setState({
							  discountTypeApplied: "credit",
							  discountErrors: "",
							  creditAmountAvailable: res.remaining_credit,
							  creditAmountApplied: res.credit_amount_applied,
							  checkoutTotal: res.new_total
						  })
			  }
			}.bind(this),
		  });
		};
	}

	resetDiscountApplied() {
		if(this.state.discountTypeApplied == "credit") {
			let updatedTotal = this.state.checkoutTotal + this.state.creditAmountApplied
			this.setState({
				creditAmountAvailable: this.state.creditAmountApplied,
				discountTypeApplied: "",
				checkoutTotal: updatedTotal
			})
		} else if(this.state.discountTypeApplied == "coupon") {
			let updatedTotal = this.state.checkoutTotal + this.state.couponDiscountAmount
			this.setState({
				discountTypeApplied: "",
				checkoutTotal: updatedTotal
			})
		}
	}

	checkCheckoutCompleted() {
		// debugger
		if(this.props.uid) {
			if(this.state.paymentCompleted) {
				// if (this.state.heardAboutKitsplit == "other" && !this.state.heardAboutKitsplitOther) {
				// 	return false;
				// } else {
				// 	return true;
				// }
				return true;
			} else {
				return false;
			}
		} else {
			if(this.state.signUpEmail !== "" && this.state.firstNameForm !== "" && this.state.lastNameForm !== "" &&  this.state.paymentCompleted && this.state.heardAboutKitsplit !== "Select an option" && this.state.validEmail === true) {
				// if (this.state.heardAboutKitsplit == "other" && !this.state.heardAboutKitsplitOther) {
				// 	return false;
				// } else {
				// 	return true;
				// }
				return true;
			} else {
				return false;
			}
		}
	}

  checkUniqueEmailForClassGuestCheckout(userEmail) {

    if (userEmail != "" && isEmail(userEmail)) {

      $('#email-invalid').fadeOut(400);

      if (!isUniqueEmail(userEmail) && userEmail != "") { 
        $('#email-already-taken-msg').fadeIn(400);
        this.state.validEmail = false;
      } else {
        $('#email-already-taken-msg').fadeOut(400);
        this.state.validEmail = true;
      }
    } else {
      $('#email-invalid').fadeIn(400);
      this.state.validEmail = false;

    }

    
  }

	render() {
		const requestRentalTooltip = (
		  <Tooltip id="tooltip">
		    Please complete all fields before requesting rental 
		  </Tooltip>
		);

		return (
			<div className="col-md-12">
				<h1 className="checkout-header">Checkout</h1>
				<div className="row col-md-12">
					<div className="checkout-left col-md-8">
						{ !this.props.uid ? 
							<CourseCheckoutCreateUser 
								signUpEmail={this.state.signUpEmail}
								firstNameForm={this.state.firstNameForm}
								lastNameForm={this.state.lastNameForm}
								handleFormChange={this.handleFormChange}
                checkUniqueEmailForClassGuestCheckout={this.checkUniqueEmailForClassGuestCheckout}
							/>
						: 
							""
						}
						{ this.state.paymentCompleted ?
							<CourseCheckoutPaymentComplete
								resetPaymentCompleted={this.resetPaymentCompleted}
								creditCardBrand={this.state.creditCardBrand}
								creditCardLast4={this.state.creditCardLast4}
								creditCardExpiration={this.state.creditCardExpiration}
								creditAmount={this.state.creditAmount}
							/>
						:
							<CourseCheckoutPayment
								cc = {this.props.cc}
								paymentCompleted={this.paymentCompleted}
								handleFormChange={this.handleFormChange}
								creditAmount={this.state.creditAmount}
								creditAmountApplied={this.state.creditAmountApplied}
								applyReferralCreditCourse={this.applyReferralCreditCourse}
								validateCouponCode={this.validateCouponCode}
								couponCode={this.state.couponCode}
								resetDiscountApplied={this.resetDiscountApplied}
								discountTypeApplied={this.state.discountTypeApplied}
								uid={this.props.uid}
								creditCardNumber={this.state.creditCardNumber}
								creditCardMonth={this.state.creditCardMonth}
								creditCardYear={this.state.creditCardYear}
								creditCardCvc={this.state.creditCardCvc}
								conversation_id={this.props.conversation_id}
								setDefaultCard={this.state.setDefaultCard}
							/>
						}
						{this.state.heardAboutKitsplitAtLoad == "" ?
							<div className="heard-about-kitsplit">
								<form>
									<label className="sk-form__input-wrapper--half">
										<span className="sk-form__input-label">
											*How did you hear about Kitsplit? <strong>(REQUIRED)</strong>
										</span>
										<div className="sk-form__select-wrapper">
											<select name="heardAboutKitsplit" className="sk-form__select" value={this.state.heardAboutKitsplit} onChange={this.handleFormChange}>
												<option default>Select an option</option>
												<option value="friend_colleague">Friend/colleague</option>
												<option value="organization">An organization I'm a part of</option>
												<option value="rental_house_referral">Rental house referral</option>
												<option value="google_search">Google search</option>
												<option value="facebook">Facebook</option>
												<option value="instagram">Instagram</option>
												<option value="twitter">Twitter</option>
												<option value="billboard_sign">Billboard/sign</option>
												<option value="event">Event</option>
												<option value="news_article">News/article</option>
												<option value="email_or_call_from_kitsplit">Email/call from Kitsplit</option>
												<option value="mailing_postcards">Mailing/postcards</option>
												<option value="other">Other</option>
											</select>
										</div>
										{this.state.heardAboutKitsplit == "other" ?
											<div className="sk-form__input-wrapper">
												<span className="sk-form__input-label">
													Let us know how you heard about Kitsplit
												</span>
												<input type="text" className="sk-form__text-input form-control" name="heardAboutKitsplitOther" value={this.state.heardAboutKitsplitOther} onChange={this.handleFormChange} />
											</div>
										:
											<div>
											</div>
										}
									</label>
								</form>
							</div>
						: 
							""
						}
						{ this.props.classType == ("group" ||"multi_course") ? <p className="insurance-choice-cost">* Sales for group classes are final and non-refundable.</p> : "" }
						<div className="checkout-request-btn">
							{ !this.checkCheckoutCompleted() ? 
								<button disabled={!this.checkCheckoutCompleted()}  className="btn sk-button--size-xlg" >BOOK NOW</button>
							:
								<button id="course-book-now-btn" className="btn sk-button--size-xlg" onClick={this.submitCourseCheckout}>BOOK NOW</button>
							}
						</div>
					</div>
					<div className="checkout-right col-md-4">
						
						<CourseListingsSummary
							listingObj={this.props.listingObj}
							pricePerSession={this.props.pricePerSession}
						/>
						<CourseConversationsPriceSummary
							subtotal={this.props.subtotal}
							convenienceFee={this.props.convenienceFee}
							tax={this.props.tax}
							couponPercent={this.state.couponPercent}
							creditAmountApplied={this.state.creditAmountApplied}
							discountTypeApplied={this.state.discountTypeApplied}
							couponAmount={this.state.couponAmount}
							total={this.state.checkoutTotal}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default CourseCheckoutContainer
