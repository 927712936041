import React from "react"
import PropTypes from "prop-types"

import { Modal, Button } from "react-bootstrap"

class BidNotificationPreferencesModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.state = {
      notificationType: ""
    }
  }

//   componentWillReceiveProps(nextProps) {
//     setTimeout(bindCalendarEvents, 1000)
//   }


  handleClose() {
    this.props.onClose();
  }

  render () {
    return (
      <Modal show={this.props.visible} onHide={this.handleClose}>
        <Modal.Header className="bid-modal-header" closeButton>
        </Modal.Header>
        <Modal.Body className="bid-notification-modal">
            <div className="bid-notifications-container">
                <div className="headers-container">
                    <h3 className="header">
                        <b>Stay up to date on the latest projects accepting quotes</b>
                    </h3>
                    <p className="sub-header">
                        Choose how often you'd like to be notified for projects in your region.
                    </p>
                </div>
                <div className="notification-options">
                    <div className={this.props.userNotificationPreferences === "immediate" ? "option-selected" : "option"}>
                    <input type="radio" name="projectNotification" checked={this.props.userNotificationPreferences === "immediate"} onChange={() => this.props.setNotification("immediate")} />
                    <b>Immediately</b> -- Receive an email each time a new project in your region is posted.
                    </div>
                    <div className={this.props.userNotificationPreferences === "daily" ? "option-selected" : "option"}>
                    <input type="radio" name="projectNotification" checked={this.props.userNotificationPreferences === "daily"} onChange={() => this.props.setNotification("daily")} />
                    <b>Daily Digest</b> -- Receive one email each day with new projects in your region.
                    </div>
                    <div className={this.props.userNotificationPreferences === "none" ? "option-selected" : "option"}>
                    <input type="radio" name="projectNotification" checked={this.props.userNotificationPreferences === "none"} onChange={() => this.props.setNotification("none")} />
                    <b>Opt Out of Notifications</b>-- I don’t want the opportunity to bid on projects. 
                    </div>
            </div>

            <button className="sk-button--color-verified" onClick={this.props.changeNotificationPreferences}>
                Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default BidNotificationPreferencesModal
