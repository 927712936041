import React from 'react';
import PropTypes from 'prop-types';
import yup from "yup";

class IndividualInsuranceTerms extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showClaimForm: false,
			errors: {}
		}

		this.validate = this.validate.bind(this);
	}

	showAddlForm(value) {
		this.setState({
			showClaimForm: value
		})
	}

	validate() {
		var schema = yup.object().shape({
			insuranceCarrier: yup.string().required("Please fill in this section"),
			policyNumber: yup.string().required("Please fill in this section"),
			dateOfLoss: yup.string()
											.matches(/2[0-9]{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/, "Please enter a valid date")
											.required("Please enter a valid date"),
			itemsInClaim: yup.string().required("Please fill in this section"),
			claimCircumstances: yup.string().required("Please fill in this section"),
		});

		const context = this;
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.submitIndividualInsuranceForm();
			})
			.catch((err) => {
				// errors
				context.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
			})
	}

	render() {
		return (
			<div>
				<div>
					<p><strong>Please review terms below: </strong></p>
				</div>
				<div className="individual-insurance-terms">
					<ol>
						<li>I verify that the rented equipment I am applying for is at least 51% production equipment</li>
						<li>I understand and agree that Production Equipment includes:
							<ul>
								<li>Cameras, camera equipment, sound, lighting and grip equipment, communications equipment, portable electric equipment, editing and projection equipment, office personal property, generators, mechanical effects equipment, props, sets, wardrobes and similar personal property and related production equipment. </li>
								</ul>
							</li>
							<li>I understand that the following are excluded and will not be covered under this insurance program:
								<ul>
									<li>General Liability, Professional Liability, Auto Liability, Auto Property Coverage, Buildings, including improvements made to your building; Aircraft, Watercraft, Motorcycles, and all motor vehicles (including automobiles); Jewelry (including Costume jewelry, i.e. jewelry not comprised of previous or semi-precious stones and/or metals), Gold, Silver, Platinum or other Precious Metals, Furs, Accounts, Bills, Deeds, Currency, Evidences of Debt, Letters or Credit, and Passports, Documents, Money, Notes, Securities, Credit Cards, and Tickets purchased for Travel; Animals or growing plants; Any Exposed, Unexposed, Developed Film and/or tape; Valuable Papers and Records</li>
								</ul>
							</li>
							<li>I understand that coverage for drone, RC helicopter, gyrocopter, and other remote-controlled aerial devices is limited to while devices are not in operation. </li>
							<li>I verify that the applicant I am applying for has an official United States mailing address.</li>
							<li>I verify that the policyholder I am applying for is NOT primarily in the business to lease and/or rent out equipment to the sole custody of others (ie. Similar to a Rental House). “Primarily” means majority or over 25% of the policyholder’s business operations. </li>
							<li>I confirm that I currently do not have a quote or active policy for production equipment insurance from Atlantic Specialty Insurance Company </li>
							<li>I understand that I will select a minimum deductible for any claim arising for theft from any vehicle of $1,000, $2,500 or $5,000. </li>
							<li>I understand that there is an exclusion for theft from an unlocked vehicle if I do not purchase unlocked vehicle coverage. </li>
							<li>I understand that coverage is based on a replacement cost basis. In the event of a claim, the insurance carrier will reimburse me for today’s cost to replace each item. The most that can be reimbursed is the limit I choose for my policy. </li>
							<li>I understand that my Rented Production Equipment Replacement Cost is the maximum amount of equipment I rent from others at ANY ONE time. If I rent from two places or individuals at the same time, the total value of equipment from both places or people should not exceed the Rented Production Equipment Replacement Cost.</li>
					</ol>
				</div>
				<div className="insurance-underwriting-questions">
					<p><strong>
						Have you had a claim paid out more than $5,000 within the last year?</strong>
					</p>
					<form className="underwriting-radio-btns">
						<div className="radio">
		            <input type="radio" name="showClaimForm" checked={this.state.showClaimForm === false} onChange={() => this.showAddlForm(false)} />
		            	No

		        </div>
		        <div className="radio">

		            <input type="radio" name="showClaimForm" checked={this.state.showClaimForm === true} onChange={() => this.showAddlForm(true)} />
		            	Yes

		        </div>
					</form>
					{ this.state.showClaimForm == true ?
						<div className="sk-form__wrapper">
				    	<div className="sk-form__row">
					    	<label className="sk-form__input-wrapper--third">
				          <span className="sk-form__input-label">
				            Insurance carrier
				          </span>
				          <input type="text" className="sk-form__text-input form-control" name="insuranceCarrier" value={this.props.insuranceCarrier} onChange={this.props.handleFormChange} />
									{this.state.errors.insuranceCarrier && <span className="concierge-warning">{this.state.errors.insuranceCarrier}</span>}
				        </label>
				        <label className="sk-form__input-wrapper--third">
				          <span className="sk-form__input-label">
				            Policy Number
				          </span>
				          <input type="text" className="sk-form__text-input form-control" name="policyNumber" value={this.props.policyNumber} onChange={this.props.handleFormChange} />
									{this.state.errors.policyNumber && <span className="concierge-warning">{this.state.errors.policyNumber}</span>}
				        </label>
				        <label className="sk-form__input-wrapper--third">
				          <span className="sk-form__input-label">
				            Date of loss
				          </span>
				          <input type="date" className="sk-form__text-input form-control" name="dateOfLoss" value={this.props.dateOfLoss} onChange={this.props.handleFormChange} />
									{this.state.errors.dateOfLoss && <span className="concierge-warning">{this.state.errors.dateOfLoss}</span>}
				        </label>
			        </div>
			        <div className="sk-form__row">
			        	<label className="sk-form__input-wrapper--two-thirds" style={{paddingTop: 0}}>
				          <span className="sk-form__input-label">
				            Items included in claim
				          </span>
				          <textarea type="text" className="sk-form__textarea form-control" name="itemsInClaim" value={this.props.itemsInClaim} onChange={this.props.handleFormChange} ></textarea>
									{this.state.errors.itemsInClaim && <span className="concierge-warning">{this.state.errors.itemsInClaim}</span>}
				        </label>
			        </div>
			      	<div className="sk-form__row">
			        	<label className="sk-form__input-wrapper--two-thirds">
				          <span className="sk-form__input-label">
				            Claim circumstances
				          </span>
				          <input type="text" className="sk-form__text-input form-control" name="claimCircumstances" value={this.props.claimCircumstances} onChange={this.props.handleFormChange} />
									{this.state.errors.claimCircumstances && <span className="concierge-warning">{this.state.errors.claimCircumstances}</span>}
				        </label>
			        </div>
						</div>
						:
						<div>
						</div>
					}
				</div>

				<hr />

				<div>
					<input name="agreeToTerms" className="terms-checkbox" type="checkbox" checked={this.props.agreeToTerms} onChange={this.props.handleCheckbox}/>
					<strong>
						By checking the box you agree to all terms above and certify that you have read through the links: <br></br>
						<span className="insurance-links">
							<a href="https://s3.amazonaws.com/kitsplit/img/heffins/Heffins_usage_agreement.pdf" target="_blank" className="sk-link-button">Usage Agreement</a>, <a href="https://s3.amazonaws.com/kitsplit/img/heffins/Heffins_privacy_policy.pdf" className="sk-link-button" target="_blank">Privacy Policy</a>, <a href="https://s3.amazonaws.com/kitsplit/img/heffins/Heffins_cancellation_policy.pdf" target="_blank" className="sk-link-button">Cancellation Policy</a>, <a href="https://s3.amazonaws.com/kitsplit/img/heffins/insure_my_equipment_claims_information.pdf" target="_blank" className="sk-link-button">Claims Information</a>, <a href="https://s3.amazonaws.com/kitsplit/img/heffins/Heffins_admin_fee_policy.pdf" className="sk-link-button" target="_blank">Administrative Fees</a>
						</span>
					</strong>
				</div>

				<div className="form-navigation">
		    	<button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
		    	<button type="button" disabled={!this.props.agreeToTerms} className={!this.props.agreeToTerms ? "disabled sk-button--size-lg next" : "sk-button--size-lg next"} onClick={this.state.showClaimForm == true ? this.validate : this.props.submitIndividualInsuranceForm}>Complete</button>
	    	</div>
			</div>
		)
	}
}

export default IndividualInsuranceTerms
