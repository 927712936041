import React from "react"
import PropTypes from "prop-types"
import yup from "yup";
import Recaptcha from "react-recaptcha";

class ConciergeStep3 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			recaptchaChecked: this.props.userId == "" ? false : true,
			showSubmittingMessage: false
		};

		this.showSubmissionMessageAndSubmitForm = this.showSubmissionMessageAndSubmitForm.bind(this)
	};

	allowSubmission() {
		this.setState({recaptchaChecked: true});
	}

	validate() {
		var schema = yup.object().shape({
			firstName: yup.string().trim().required("Please enter your first name"),
			lastName: yup.string().trim().required("Please enter your last name"),
			email: yup.string().email("Please enter a valid email").required("Please enter an email"),
			phoneNumber: yup.string().trim()			
				.required("Please enter a valid phone number"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				// this.props.nextStep();
				this.showSubmissionMessageAndSubmitForm(event);
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	showSubmissionMessageAndSubmitForm(event) {
		this.setState({ showSubmittingMessage: true })

		setTimeout(() => this.props.handleSubmitForm(event), 5000)
	}

	render() {
		const context = this;
		return (
			<div>
				{ !this.state.showSubmittingMessage ? 
					<div>
						<p className="home-form-header">Where can we send a gear quote?</p>
						<div className="sk-form__row">
							<label className="sk-form__input-wrapper--half" htmlFor="firstName">
								<span className="sk-form__input-label">
									First Name*
								</span>
								{this.state.errors.firstName && <span className="concierge-warning">{this.state.errors.firstName}</span>}
								<input type="text" className="sk-form__text-input form-control" placeholder="John" name="firstName" value={this.props.firstName} onChange={this.props.handleChange} />
							</label>
							<label className="sk-form__input-wrapper--half" htmlFor="lastName">
								<span className="sk-form__input-label">
									Last Name*
								</span>
								{this.state.errors.lastName && <span className="concierge-warning">{this.state.errors.lastName}</span>}
								<input type="text" className="sk-form__text-input form-control" placeholder="Doe" name="lastName" value={this.props.lastName} onChange={this.props.handleChange} />
							</label>
						</div>
						<div className="sk-form__row">
							<label className="sk-form__input-wrapper--half" htmlFor="email">
								<span className="sk-form__input-label">
									Email*
								</span>
								{this.state.errors.email && <span className="concierge-warning">{this.state.errors.email}</span>}
								<input type="email" className="sk-form__text-input form-control" name="email" placeholder="johndoe@gmail.com" value={this.props.email} onChange={this.props.handleChange} />
							</label>
							<label className="sk-form__input-wrapper--half" htmlFor="phoneNumber">
								<span className="sk-form__input-label">
									Phone Number*
								</span>
								{this.state.errors.phoneNumber && <span className="concierge-warning">{this.state.errors.phoneNumber}</span>}
								<input type="text" className="sk-form__text-input form-control" name="phoneNumber" placeholder="555-555-5555" value={this.props.phoneNumber} onChange={this.props.handleChange} />
							</label>
						</div>
						<div>
							{ this.props.userId == "" ? 
								<div>
									<Recaptcha
										sitekey={this.props.reCaptchaSiteKey}
										render="explicit"
										verifyCallback={this.allowSubmission.bind(this)}
									/>
								</div>
								:
								""
							}
						</div>
						
						<div className="form-navigation">
							<button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
							<input type="button" ref="submitButton"className="sk-button--size-lg" value="Submit" disabled={!this.state.recaptchaChecked} onClick={this.validate.bind(this)}/>
						</div>
					</div>
				:
					<div>
						<div className="submission-message">
							<h3 className="home-form-header form-loading">Submitting your project details</h3>
							<p>
								Our Rental Agents look through all relevant KitSplit listings to find the gear for you, on your budget.
							</p>
						</div>
					</div>
				}
            </div>
		)
	}
};

export default ConciergeStep3
