import React from "react";
import PropTypes from "prop-types";
import yup from "yup";

class ProjectType extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			showErrorMessage: false,
		};

		this.validateItems = this.validateItems.bind(this);
	}

	validate() {
		var schema = yup.object().shape({
			projectType: yup.string().required(),
		  otherProject: yup.string()
				.when('projectType', (projectType, schema) => projectType == "other"
					?yup.string().required("Please tell us about your project")
					:yup.string()),
			gearNeeded: yup.string().required("Please fill in this section"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.nextStep();
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
			})
	}

	validateItems() {
		let itemCount = 0;
		
		for(let index in this.props.gearNeeded) {
			if(this.props.gearNeeded[index]["item"] != null && this.props.gearNeeded[index]["item"] != "") {
				itemCount += 1
			} 
		}
		
		if (itemCount == 0 ) {
			this.setState({showErrorMessage: true})
		} else {
			this.props.nextStep();
		}
	}

	render() {
		return (
		<div>
			<div>
				<label htmlFor="projectType" className="sk-form__input-wrapper">
					<span className="sk-form__input-label--size-lg">
						What type of project are you planning?
					</span>
					<div className="sk-form__select-wrapper">
						<select name="projectType" className="sk-form__select project-select" value={this.props.projectType} onChange={this.props.handleChange}>
						<option value="feature film">Feature Film</option>
						<option value="documentary">Documentary</option>
						<option value="scripted tv show">Scripted TV Show</option>
						<option value="reality tv show">Reality TV Show</option>
						<option value="live event/wedding">Live Event / Wedding</option>
						<option value="promotional video">Promotional Video</option>
						<option value="web series / short form">Web Series / Short Form</option>
						<option value="music video">Music Video</option>
						<option value="commercial">Commercial</option>
						<option value="branded content">Branded Content</option>
						<option value="social media content">Social Media Content</option>
						<option value="photoshoot">Photo Shoot</option>
						<option value="other">Other</option>
						</select>
					</div>
				</label>

	    		<div className={"other-project " + (this.props.projectType == "other" ? "" : "hidden")}>
					<label htmlFor="otherProject" className="sk-form__input-wrapper">
						<span className="sk-form__input-label--size-lg">
						Tell us about your project?
						</span>
								{this.state.errors.otherProject && <span className="concierge-warning">{this.state.errors.otherProject}</span>}
						<input type="text" className="sk-form__text-input form-control" name="otherProject" value={this.props.otherProject} onChange={this.props.handleChange} />
					</label>
				</div>

		    
				<label htmlFor="projectType" className="sk-form__input-wrapper">
					<span className="sk-form__input-label--size-lg">
						Items
					</span>
					{ this.state.showErrorMessage ? 
						<span className="concierge-warning">
						Please enter an item
						</span>
						: 
						""
					}
					<div>
						{ this.props.showListView ? 
						<div>
							<label className="sk-form__input-wrapper">
							<div>
								{
								this.props.gearNeeded.map((gear, idx) => <input type="text" className="sk-form__text-input" name="gearNeeded" key={idx} data-key={idx} placeholder={gear.placeholder ? gear.placeholder : "Add additional item"} value={gear.item || ''} onChange={this.props.handleGearListChange} />)
								}
							</div>            
							</label>
							<button type="button" className="sk-link-button" onClick={this.props.addGear}>+ Add Item</button>
						</div>
						:
						<div>
							<label htmlFor="gearNeeded" className="sk-form__input-wrapper">
							<textarea className="sk-form__textarea form-control concierge-text-area" name="gearNeededList" rows="5" placeholder="- 1x Arri Alexa Mini&#10;- 1x Set of Sigma Cine Primes (24, 35, 50, 85)&#10;- Small Lighting & Grip package TBD&#10;- audio package w/ boom, lav, and recorder" value={this.props.gearNeededList} onChange={this.props.handleChange} ></textarea>
							</label>
						</div>
						}
					</div>
				</label>
            </div>
			<div className="switch-form-link">
				<a href="#" className="sk-link-button--color-white" onClick={this.props.switchFormInputType}>
				{ this.props.showListView ? "Long List?" : "Single Items" }
				</a>
			</div>
		    <div className="form-navigation">
		    	<button type="button" className="sk-button--color-edu--size-lg next" onClick={this.validateItems}>Continue</button>
		    </div>
	    </div>
		)
	}
}

export default ProjectType
