import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from "react-bootstrap"

class CheckoutPayment extends React.Component {
	constructor(props) {
		super(props);

		let cc  = -1;
		let ccID = '' + (cc + 1);
		let brand = '';
		let last4 = '';
		let expiration = '';

		if (this.props.cc) {
			cc = 0 	// default to first payment method
			ccID = this.props.cc.data[0]['id']
			brand = this.props.cc.data[0]['brand']
			last4 = this.props.cc.data[0]['last4']
			expiration = this.props.cc.data[0]['exp_month'] + '/' + this.props.cc.data[0]['exp_year']
		}

		this.state = {
			cc: this.props.cc || [],
			selectedCreditCard: cc,
			creditCardNumber: "",
			creditCardMonth: "01",
			creditCardYear: "2020",
			creditCardCvc: "",
			creditCardSubmitted: false,
			creditCardErrorMsg: "",
			creditCardId: ccID,
			creditSelected: false,
			couponSelected: false,
			selectedCardLast4: "",
			creditCardBrand: brand,
			creditCardLast4: last4,
			creditCardExpiration: expiration,
			hittingStripeApi: false
		};

		this.handleOptionChange = this.handleOptionChange.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.submitPayment = this.submitPayment.bind(this);
		this.submitPaymentCreateCard = this.submitPaymentCreateCard.bind(this);
	}

	componentDidMount() {
		if(this.props.setDefaultCard && this.state.selectedCreditCard != -1 && this.state.creditCardId) {
			this.submitPayment();
		}
	}

	handleOptionChange(changeEvent) {
	  this.setState({
	    selectedCreditCard: changeEvent.target.value,
	    creditCardId: changeEvent.target.id,
			creditCardBrand: changeEvent.target.getAttribute('brand'),
			creditCardLast4: changeEvent.target.getAttribute('card'),
			creditCardExpiration: changeEvent.target.getAttribute('expiration')
	  });
	}

	handleFormChange(event) {
		this.setState({
			[event.target.name] : event.target.value
		})
	}

	discountApplied(first, second) {
		this.setState({
			[first]: true,
			[second]: false
		})

		this.props.resetDiscountApplied();
	}

	submitPayment() {
	  if (this.state.creditCardSubmitted) {
	    // do nothing
	  } else {
	    this.setState({
	    	// creditCardSubmitted: true,
	    	hittingStripeApi: true
	    })

	    if (this.state.selectedCreditCard == -1) {
	      Stripe.card.createToken({
	        number: this.state.creditCardNumber,
	        cvc: this.state.creditCardCvc,
	        exp_month: this.state.creditCardMonth,
	        exp_year: this.state.creditCardYear,
	      }, this.submitPaymentCreateCard);
	    } else {
	    	const card_id = this.state.creditCardId;
	    	const stripeToken = null;

	    	this.submitPaymentExistingCard(card_id, stripeToken);
				// rdday clean up
	      // var submitSuccess = this.submitPaymentExistingCard(card_id, stripeToken);
	      // if (submitSuccess) {

	      //   return true;
	      // } else {
	      //   return false;
	      // }
	    }
	  }
	}

	submitPaymentCreateCard(status, response) {
	  if (response.error) {
	    // Show the errors on the form
	    this.setState({
				creditCardErrorMsg: "Sorry, there was an error processing your credit card: " + response.error.message,
				hittingStripeApi: false
	    })

	    return false;
	  } else {
			let cardDataCopy  = this.state.cc.data || []
			let newCardData 	= []

			if (cardDataCopy.length > 0) {
				cardDataCopy.push(response.card);
				newCardData = cardDataCopy;
			} else {
				newCardData = [response.card]
			}
			let newCardIndex 	= newCardData.indexOf(response.card)

			this.setState({
				cc: newCardData[newCardIndex]
			})
	    // response contains id and card, which contains additional card details

	    var token = response.id;
	    var card_id = this.state.creditCardId;

	    // Clean sensitive info from divs & necessary info to render new card
	    this.setState({
	    	creditCardErrorMsg: '',
	    	creditCardNumber: '',
	    	creditCardCvc: '',
	    	creditCardMonth: '',
	    	creditCardYear: '',
				newCardBrand: newCardData[newCardIndex]['brand'],
				newCardLast4: newCardData[newCardIndex]['last4'],
				newCardExpiration: newCardData[newCardIndex]['exp_month'] + '/' + newCardData[newCardIndex]['exp_year']
	    })

	    // and submit
	    var submitSuccess = this.submitPaymentAjax(card_id, token);
	    if (submitSuccess) {
	      // In this flow, we go to next step after creating the cc in this callback

	      return true;
	    } else {
	      return false;
	    }
	  }
	}

	submitPaymentExistingCard(card_id, stripeToken) {
		this.submitPaymentAjax(card_id, stripeToken);
		// rdday clean up
	  // var submitSuccess = this.submitPaymentAjax(card_id, stripeToken);
	  // if (submitSuccess) {
	  //   return true;
	  // } else {
	  //   return false;
	  // }
	}

	submitPaymentAjax(card_id, stripeToken) {
	  // var submitSuccess = false;
	  var url = '/add-payment-info-to-rentals';
	  var prevToken = stripeToken;
	  $.ajax({
	    type: 'PATCH',
	    data: {card_id: card_id, stripeToken: stripeToken, bid_conversation_id: this.props.bidConversationId},
	    url: url,
	    dataType: 'json',
	    // async: false,
	    success: function (res) {
	      // const submitSuccess = res.success;
	      this.setState({hittingStripeApi: false})
	      if (res.success) {
	        // great!
					const brand = this.state.newCardBrand || this.state.creditCardBrand;
					const lastFour = this.state.newCardLast4 || this.state.creditCardLast4;
					const expiration = this.state.newCardExpiration || this.state.creditCardExpiration;

					this.props.paymentCompleted(brand, lastFour, expiration);
					this.setState({creditCardSubmitted: true})
					document.body.scrollTop = document.documentElement.scrollTop = 0;
	      } else {
	       	this.setState({
						 creditCardErrorMsg: "Sorry, there was an error processing your credit card. Please use a different card and try again. The reported error message is: " + res.error_msg + ".",
						 hittingStripeApi: false
	       	})
	      }
	    }.bind(this)
	  });

	  // return submitSuccess;
	}

	renderCreditCards() {
		let creditCards = {};
		let creditCardsRender = [];

		if (this.props.cc) {
			creditCards = this.props.cc.data
			creditCardsRender = creditCards.map((card, index) => {
				return (
	 				<div key={index} className="radio cc-option">
	 					<input type="radio" value={index} id={card['id']} brand={(card['brand']).toLowerCase().replace(/ /g,'')} card={card['last4']} expiration={card['exp_month'] + '/' + card['exp_year']} checked={ index == this.state.selectedCreditCard ? true : false} onChange={this.handleOptionChange} />
	 					<img src={`https://s3.amazonaws.com/kitsplit/img/icons/cc-${(card['brand']).toLowerCase().replace(/ /g,'')}-icon.png`} />
	 					 **** **** **** {card['last4']}
	 					 <div className="cc-expiration">
	 					 	Expires: {card['exp_month']}/{card['exp_year']}
	 					 </div>
	 				</div>
 				)
 			});
		}

		return creditCardsRender;
	}

	render() {
		const tooltip = (
		  <Tooltip id="tooltip">
		    This is the amount from referral credits earned that you are able to apply to your total
		  </Tooltip>
		);
		return (
			<div className="checkout-payments-container">

				<h3 className="insurance-header">Payment</h3>
					<div>
						<div className="sk-message--size-xsm--text-black--one-liner">
		          <svg className="onboarding__vet-app-1__secure-icon">
		            <use xlinkHref="#lock"/>
		          </svg>
		          Your payment information will be kept secure.
		        </div>
		        { this.state.creditCardErrorMsg == "" ?
		        	<div></div> :
		        	<div className="sk-message--color-danger">
		        		{this.state.creditCardErrorMsg}
		        	</div>
		        }
						<form>
							{this.renderCreditCards()}
							<div className="add-new-cc">
								<input type="radio" value="-1" id="1" checked={ -1 == this.state.selectedCreditCard ? true : false} onChange={this.handleOptionChange} />
								Add new card
							</div>
						</form>
						{ -1 == this.state.selectedCreditCard ?
							<div className="sk-form__wrapper  cc-form">
								<label className="sk-form__input-wrapper--third">
									<span className="sk-form__input-label">
										Card Number
									</span>
									<input type="text" className="sk-form__text-input form-control" name="creditCardNumber" value={this.state.creditCardNumber} onChange={this.handleFormChange} />
								</label>
								<div className="sk-form__row">
									<label className="sk-form__input-wrapper--quarter">
										<span className="sk-form__input-label">
											Month
										</span>
										<div className="sk-form__select-wrapper">
							        <select name="creditCardMonth" className="sk-form__select" value={this.state.creditCardMonth} onChange={this.handleFormChange}>
							          <option value="01">Jan</option>
							          <option value="02">Feb</option>
							          <option value="03">Mar</option>
												<option value="04">Apr</option>
												<option value="05">May</option>
												<option value="06">Jun</option>
												<option value="07">Jul</option>
												<option value="08">Aug</option>
												<option value="09">Sep</option>
												<option value="10">Oct</option>
												<option value="11">Nov</option>
												<option value="12">Dec</option>
							        </select>
							      </div>
									</label>
									<label className="sk-form__input-wrapper--quarter">
										<span className="sk-form__input-label">
											Year
										</span>
										<div className="sk-form__select-wrapper">
							        <select name="creditCardYear" className="sk-form__select" value={this.state.creditCardYear} onChange={this.handleFormChange}>
							          <option value="2020">2020</option>
												<option value="2021">2021</option>
												<option value="2022">2022</option>
												<option value="2023">2023</option>
												<option value="2024">2024</option>
												<option value="2025">2025</option>
												<option value="2026">2026</option>
												<option value="2027">2027</option>
												<option value="2028">2028</option>
							        </select>
							      </div>
									</label>
									<label className="sk-form__input-wrapper--quarter">
										<span className="sk-form__input-label">
											CVC
										</span>
									<input type="text" className="sk-form__text-input form-control cvc-input" name="creditCardCvc" value={this.state.creditCardCvc} onChange={this.handleFormChange} />
									</label>
								</div>
							</div>
							: null
						}
						<div className="select-payment row">
							<button className="btn sk-button--size-lg col-sm-5" onClick={this.submitPayment}>
								{this.state.hittingStripeApi == true ? <div className="js-loading "><span className="glyphicon glyphicon-refresh spinning"></span> Loading</div> : <div className="js-payment-btn">USE THIS PAYMENT METHOD</div> }
							</button>
              { this.props.autoDiscountedConversation == false ?
                <div>
    							{ this.props.discountTypeApplied == "" ?
    								<div className="credit-coupon-container col-sm-4 col-sm-offset-3">
    									{this.props.creditAmount > 0 ? <p>You have <strong>${this.props.creditAmount}</strong> in credit available!</p> : ""}
    									{!this.state.couponSelected && !this.state.creditSelected ?
    											this.props.creditAmount > 0 ?
    												<div>
    													<button className="sk-link-button" onClick={() => this.discountApplied("creditSelected", "couponSelected")}>
    														USE CREDIT
    													</button> or <button className="sk-link-button" onClick={() => this.discountApplied("couponSelected", "creditSelected")}>
    														USE COUPON
    													</button>
    												</div>
    												:
    												<div>
    													<button className="sk-link-button change-coverage-link" onClick={() => this.discountApplied("couponSelected", "creditSelected")}>
    														USE COUPON
    													</button>
    												</div>
    									:
    										""
    									}
    									<div>
    										{ this.state.creditSelected ?
    											<label className="sk-form__input-wrapper discount-form">
    												<span className="sk-form__input-label">
    							            CREDIT TO APPLY
    													<OverlayTrigger placement="bottom" overlay={tooltip}>
    														<span className="glyphicon glyphicon-question-sign" data-toggle="tooltip" data-placement="bottom" data-html="true" title="Referral credit available to deduct from your total amount"></span>
    													</OverlayTrigger>
    							          </span>

    												{ this.props.discountErrors ? <p className="sk-message--size-sm sk-message--color-danger">{this.props.discountErrors}</p> : "" }
    												<input type="text" className="sk-form__text-input form-control" name="creditAmountApplied" value={this.props.creditAmountApplied} onChange={this.props.handleFormChange} />
    												<button className="sk-button--size-sm apply-discount-btn" onClick={this.props.applyReferralCredit}>Apply</button>
    												<button className="sk-link-button discount-btn" onClick={() => this.discountApplied("couponSelected", "creditSelected")}>
    													USE COUPON INSTEAD
    												</button>
    											</label> : ""
    										}
    										{ this.state.couponSelected ?
    											<label className="sk-form__input-wrapper discount-form">
    												<span className="sk-form__input-label">
    													COUPON CODE
    												</span>
    												{ this.props.discountErrors ? <p className="sk-message--size-sm sk-message--color-danger">{this.props.discountErrors}</p> : "" }
    												<input type="text" className="sk-form__text-input form-control" name="couponCode" value={this.props.couponCode} onChange={this.props.handleFormChange} />
    												<button className="sk-button--size-sm apply-discount-btn" onClick={this.props.validateCouponCode}>Apply</button>
    												{this.props.creditAmount > 0 ?
    													<button className="sk-link-button discount-btn" onClick={() => this.discountApplied("creditSelected", "couponSelected")}>
    														USE CREDIT INSTEAD
    													</button> : ""
    												}
    											</label> : ""
    										}
    									</div>
    								</div>
    							:
    								<div className="credit-coupon-container col-sm-4 col-sm-offset-3">
    									<p><strong>Your {this.props.discountTypeApplied} has been applied!</strong></p>
    									{
    										this.props.discountTypeApplied == "credit" ?
    										<button className="sk-link-button discount-btn" onClick={() => this.discountApplied("couponSelected", "creditSelected")}>
    											USE COUPON INSTEAD
    										</button>
    										:
    										 this.props.creditAmount > 0 ?
    											<button className="sk-link-button discount-btn" onClick={() => this.discountApplied("creditSelected", "couponSelected")}>
    												USE CREDIT INSTEAD
    											</button>
    											:
    											""
    										
    									}
    								</div>
    							}
                </div>
              :
                <div className="credit-coupon-container col-sm-4 col-sm-offset-3">
                  <button className="sk-link-button checkout-payments-inactive" data-toggle="tooltip" data-placement="top" title="Sorry, You cannot use a coupon for this rental.">
                    USE COUPON
                  </button>
                </div>
              }
						</div>
					</div>
			</div>
		)
	}
}

export default CheckoutPayment
