import React from "react"
import PropTypes from "prop-types"
import ListingRequestInfoModal from "./ListingRequestInfoModal"
import ListingRequestMapModal from "./ListingRequestMapModal"
import SingleMultipleRentalRequest from "./SingleMultipleRentalRequest"

class MultipleRentalRequestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current_index: 1,
      show_info_modal: false,
      info_modal_attrs: {},
      show_map_modal: false,
      map_modal_attrs: {},
      related_listings: this.props.related_listings
    };

    this.triggerListingInfoModal = this.triggerListingInfoModal.bind(this);
    this.triggerListingMapModal = this.triggerListingMapModal.bind(this);
    this.onWindowSkip = this.onWindowSkip.bind(this);
  }

  onModalClose(e) {
    if (e)
      e.preventDefault();

    var $wrapper = $(e.target).parents('.sk-modal__wrapper');
    var $modal = $wrapper.find('.sk-modal__card');

    const context = this;
    $modal.fadeOut('500', function() {
      $wrapper.fadeOut('300', function () {
        context.setState({
          show_map_modal: false,
          map_modal_attrs: {},
          show_info_modal: false,
          info_modal_attrs: {}
        });
      });
    });
  }

  onWindowSkip(e, requestCardContext) {
    if (e) {
      e.preventDefault();

      let context = this;
      let target = e.target;
      let nextIndex = context.state.current_index + 1
      const listingsCount = this.state.related_listings.length;

      if (nextIndex <= listingsCount) {
        $('.request-card').addClass('loading');

        setTimeout(function () {
          context.setState({ current_index: nextIndex });
          requestCardContext.setState({
            listings_data: context.state.related_listings[context.state.current_index - 1]
          });

          $('html, body').animate({
            scrollTop: $('.request-card').offset().top - 100
          }, 1000);

          $('input#request_listing:checked').removeAttr('checked');

          $('.request-card').removeClass('loading');
        }, 2000);
      } else {
        const shootId = getUrlParameter('shoot');
        const unconfirmed = getUrlParameter('unconfirmed');

        let url = Routes.checkout_success_path() + '?shoot=' + shootId;

        if (unconfirmed)
          url += '&unconfirmed=true';

        window.location.href = url;
      }

      return {
        total_count: listingsCount,
        current_index: this.state.current_index
      }
    }
  }

  skipConcierge(e) {
    if (e) {
      e.target.classList.add('--color-ink');
      $('.request-card').addClass('loading');

      setTimeout(function () {
        const shootId = getUrlParameter('shoot');
        const unconfirmed = getUrlParameter('unconfirmed');

        let url = Routes.checkout_success_path() + '?shoot=' + shootId;

        if (unconfirmed)
          url += '&unconfirmed=true';

        window.location.href = url;
      }, 2000);
    }
  }

  triggerListingInfoModal(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();

      const $infoModal = $('#listingRequestModal');
      if ($infoModal.length > 0)
      $infoModal.remove();

      const listingId = $(event.target).parents('.listing-request').data('id');

      let context = this;

      $.ajax({
        url: Routes.cart_listing_detail_view_path({format: 'json'}),
        type: 'PATCH',
        dataType: 'json',
        data: { listing_id: listingId },
        success: function (res) {
          let modalAttributes = res.success;

          modalAttributes.onModalClose = context.onModalClose.bind(context);

          context.setState({
            show_info_modal: true,
            info_modal_attrs: res.success
          })
        }
      });
    }

  }

  triggerListingMapModal(event, coords) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();

      const $mapModal = $('#listingMapModal');
      if ($mapModal.length > 0)
      $mapModal.remove();

      let mapAttributes = coords;
      mapAttributes.onModalClose = this.onModalClose.bind(this);

      this.setState({
        show_map_modal: true,
        map_modal_attrs: coords
      })
    }
  }

  sendConciergeMessage(e) {
    if (e) {
      e.preventDefault();

      let userMsg = this.refs.userMessage.value;

      $('.request-card').addClass('loading');
      if (userMsg !== '') {
        this.refs.errorNotice.classList.add('hidden');

        $.ajax({
          url: Routes.concierge_checkout_message_path({format: 'json'}),
          type: 'POST',
          dataType: 'json',
          data: {
            user_message: userMsg,
            user_id: $('input#ks_c_user_id').val(),
            shoot_id: getUrlParameter('shoot')
          },
          success: function (res) {
            setTimeout(function () {
              const shootId = getUrlParameter('shoot');
              const unconfirmed = getUrlParameter('unconfirmed');

              let url = Routes.checkout_success_path() + '?shoot=' + shootId;

              if (unconfirmed)
                url += '&unconfirmed=true';

              window.location.href = url;
            }, 2000);
          }
        })
      } else {
        this.refs.errorNotice.classList.remove('hidden');

        $('.request-card').removeClass('loading');
      }
    }
  }

  renderInfoModal() {
    if (this.state.show_info_modal) {
      return <ListingRequestInfoModal {...this.state.info_modal_attrs} />
    } else  {
      return <div></div>
    }
  }

  renderMapModal() {
    if (this.state.show_map_modal) {
      return <ListingRequestMapModal {...this.state.map_modal_attrs} />
    } else {
      return <div></div>
    }
  }

  renderRequestCard() {
    return (
      <SingleMultipleRentalRequest
        start_date={this.props.start_date}
        end_date={this.props.end_date}
        start_time={this.props.start_time}
        end_time={this.props.end_time}
        listings_data={this.state.related_listings[this.state.current_index - 1]}
        triggerListingInfoModal={this.triggerListingInfoModal}
        triggerListingMapModal={this.triggerListingMapModal}
        skipThisWindow={this.onWindowSkip}
      />
    )
  }


  renderListingRequests() {
    return (
      <div className="container-fluid">
        <div className="space-above-80"></div>

        <div className="multi-request-cta row" style={{zoom: 1.05}}>
          <div className="col-sm-12 col-md-8 col-md-offset-2">
            <div className="row text-center almost-done">
              <span>Rental Requested!</span>
            </div>

            { this.renderRequestCard() }

            <div className="row text-center almost-done">
              <span ref="currentRequestIndex">{ this.state.current_index }</span> <span>of { this.props.related_listings.length }</span>
            </div>
          </div>
        </div>

        { this.renderInfoModal() }
        { this.renderMapModal() }
      </div>
    )
  }

  renderConciergeCTA() {
    return (
      <div className="container-fluid">
        <div className="space-above"></div>

        <div className="multi-request-cta row">
          <div className="col-sm-12 col-md-8 col-md-offset-2">
            <div className="row text-center almost-done"><span>RENTAL REQUESTED!</span></div>

            <div className="request-card">
              <div className="make-sure-you-get-one row">
                <div className="col-sm-7">
                  <h3>
                    Renting more gear? Let us help!
                  </h3>
                </div>

                <div className="col-sm-4 col-sm-offset-1">
                  <div class="person concierge-person">
                    <div class="concierge-cs-portrait">
                      <img src="https://s3.amazonaws.com/kitsplit/img/homepage/cs-danny.jpg" alt="Cs danny" />
                    </div>
                  </div>

                  <div class="person concierge-person">
                    <div class="concierge-cs-portrait">
                      <img src="https://s3.amazonaws.com/kitsplit/img/homepage/cs-allyson.jpg" alt="Cs allyson" />
                    </div>
                  </div>
                  <div class="person concierge-person">
                    <div class="concierge-cs-portrait">
                      <img src="https://s3.amazonaws.com/kitsplit/img/homepage/cs-daniel.jpg" alt="Cs daniel" />
                    </div>
                  </div>
                  <div class="person concierge-person">
                    <div class="concierge-cs-portrait">
                      <img src="https://s3.amazonaws.com/kitsplit/img/homepage/cs-lauren.jpg" alt="Cs lauren" />
                    </div>
                  </div>
                  <div class="person concierge-person">
                    <div class="concierge-cs-portrait">
                      <img src="https://s3.amazonaws.com/kitsplit/img/homepage/cs-chris.jpg" alt="Cs chris" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row col-sm-12">
                <p className="concierge-subtext">Tell us what items you need and our staff can help you find the best deal. KitSplit concierge operates between 10am-9pm M-F, 10am-6pm weekends.</p>

                <p className="concierge-subtext">
                  <span><strong>Call us:</strong >917.722.6792</span>
                  <span><strong>Email us:</strong> contact@kitsplit.com</span>
                </p>

                <p className="concierge-subtext">Or <strong>Write</strong> a list of gear you need here and we'll get back to you via email!</p>
              </div>

              <div className="row">
                <div className="col-xs-10 checkout-concierge-msg-container">
                  <form className="sk-form__wrapper">
                    <div className="sk-form__row">
                      <label className="sk-form__input-wrapper no-padding">
                        <textarea ref="userMessage" className="sk-form__textarea" placeholder="eg. Canon C200, compatable shoulder mount, 18mm prime lens, and 2 sandbags"></textarea>
                          <div className="sk-message--color-danger hidden" ref="errorNotice">
                            <p>Message cannot be empty.</p>
                          </div>
                      </label>
                    </div>

                    <div className="sk-modal__actions" style={{}}>
                      <a href="#" className="sk-link-button" onClick={this.skipConcierge}>
                        Skip
                      </a>
                      <a href="#" className="sk-button" onClick={this.sendConciergeMessage.bind(this)}>
                        Send Message
                      </a>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContainer() {
    return this.renderConciergeCTA();
  }

  render() {
    return this.renderContainer();
  }
}


export default MultipleRentalRequestContainer
