import React from 'react';
import PropTypes from 'prop-types';

class CartListingsSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const conversations = this.props.conversationsList.map((convo, index) => {
				return (
					<div className="conversation-block" key={index}>
						<p>
							<i>{convo.listings_count} from <b>{convo.owner_name}</b></i>
							<img src={convo.owner_avatar} className="checkout-owner-avatar" />
						</p>
						<div className="conversation-listings">
							{
								convo.listings.map((listing, index) => {

									return (
										<div key={index} className="checkout-listing-item">
											<img src={listing.listing_img} className="checkout-listing-img" />
											<div className="checkout-listing-details">
												<p><strong>{listing.listing_name}</strong></p>
                        <p className="listing-item-subdetails">
                          {listing['is_discounted_listing'] == true &&
                            <span className="text-red">${listing.price_per_day.toLocaleString()} x {convo.rental_length} paid days</span>
                          }
                          {listing['is_discounted_listing'] == false &&
                            <span className="">${listing.price_per_day.toLocaleString()} x {convo.rental_length} paid days</span>
                          }
                        </p>
                        <p className="listing-item-subdetails listing-replacement-value">
                          {listing['is_discounted_listing'] == true &&
                            <span className="strikethru-price">${listing.full_price_per_day.toLocaleString()}</span>
                          }
                          {listing['is_discounted_listing'] == true ? '  ' : ''} Value: ${listing.replacement_value.toLocaleString()}
                        </p>
												<p className="listing-item-subtotal">${listing.subtotal.toLocaleString()}</p>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				)
		})
		return (
			<div className="cart-listing-summary-container">
				<div>
					<h3 className="item-header">{this.props.items.length} {this.props.items.length == 1 ? "Item" : "Items"}</h3>
					<a href="/cart" className="change-coverage-link sk-link-button">EDIT</a>
				</div>
				<div className="listings">
					{conversations}
				</div>
			</div>

		)
	}
}

export default CartListingsSummary
