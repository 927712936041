import React from "react";
import PropTypes from "prop-types";
import yup from "yup";

class ConciergeStep2 extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            errors: {},
        }
    }
    componentDidMount() {
        bindCalendarEvents();
        // initConciergeLocationAutocomplete();
    }
    
    validate() {
		var schema = yup.object().shape({
			pickUpDate: yup.string().required("Please enter a date"),
			dropOffDate: yup.string().required("Please enter a date"),
		});
		schema.validate(this.props, {abortEarly: false})
			.then(() => {
				// validation successful
				this.props.nextStep();				
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	render() {
		return (
            <div>
                <p className="home-form-header">When & where are you renting?</p>
                <p className="home-form-subheader">
                    With over 45,000+ gear listings, KitSplit finds you the best rental, on your budget.
                </p>
                <div className="sk-form__row">
                    <label className="sk-form__input-wrapper--half">
                        <span className="sk-form__input-label">
                            Pick Up
                        </span>
                        {this.state.errors.pickUpDate && <span className="concierge-warning">{this.state.errors.pickUpDate}</span>}          
                        <div id="listing_start_date" className="input-group date datepicker">
                            <input id='start_date' name="pickUpDate" type="text" className="start_date form-control make-datepicker ks-input sk-form__text-input" placeholder="MM-DD-YYYY" autoComplete="off" value={this.props.pickUpDate} onChange={this.props.handleChange} onBlur={this.props.handleChange} />
                            <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                            </span>
                        </div>
                    </label>
                    <label className="sk-form__input-wrapper--half">
                        <span className="sk-form__input-label">
                            Dropoff
                        </span>
                        {this.state.errors.dropOffDate && <span className="concierge-warning">{this.state.errors.dropOffDate}</span>}                  
                        <div id="listing_end_date" className="input-group date datepicker">
                            <input id='end_date' name="dropOffDate" type="text" className="end_date form-control make-datepicker ks-input sk-form__text-input" placeholder="MM-DD-YYYY" autoComplete="off" value={this.props.dropOffDate} onChange={this.props.handleChange} onBlur={this.props.handleChange} />
                            <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                            </span>
                        </div>
                    </label>
                </div>
                <label className="sk-form__input-wrapper">
					<span className="sk-form__input-label">
                        Gear Pickup Location
                    </span>
                    <input type="text" id="concierge-location-field" className="sk-form__text-input form-control" placeholder="Brooklyn, Los Angeles, etc" name="location" value={this.props.location} autoComplete="off" onChange={this.props.handleChange} onBlur={this.props.handleChange} />
				</label>

                <div className="form-navigation">
                    <button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
                    <button type="button" className="sk-button--size-lg next" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </div>
		)
	}
}

export default ConciergeStep2
