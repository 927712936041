import React from "react"
import PropTypes from "prop-types"
import yup from "yup";

class HomepageConciergeComplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			userPassword: ""
		}

		this.createNewUser = this.createNewUser.bind(this)
		this.handleNewUserForm = this.handleNewUserForm.bind(this)
	}

	handleNewUserForm(event) {
		this.setState({
			[event.target.name] : event.target.value
		})
	}

	validate() {
		var schema = yup.object().shape({
			userPassword: yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
		});
		schema.validate(this.state, {abortEarly: false})
			.then(() => {
				// validation successful
				this.createNewUser(event);
			})
			.catch((err) => {
				// errors
				this.setState({
					errors:
						err.inner.reduce((errors, val) => {
							errors[val.path] = val.message;
							return errors;
						}, {})
				});
				console.log(err.inner.reduce((errors, val) => {
					errors[val.path] = val.message;
					return errors;
				}, {}))
			})
	}

	createNewUser(e) {
		e.preventDefault();
		var data = {
			"first_name": this.props.firstName,
			"last_name": this.props.lastName,
			"email": this.props.email,
			"phone_number": this.props.phoneNumber,
			"password": this.state.userPassword
		}
		
		$.ajax({
			url: "/create-new-concierge-user",
			type: 'PATCH',
			dataType: 'json',
			data: data,

			success: function (response) {
				e.preventDefault();
				if(response.success == true) {
          if(response.existing_user == true) {
            $('.js-email-exists').removeClass('hidden');
          } else {
            $('.js-email-exists').addClass('hidden');
            window.location.href = response.path;  
          }
				}
				
			}.bind(this),
			error: function (response) {

			}
		});
	}

	render() {

		return (
			<div className="concierge-complete">
				<div>
					<h3 className="form-header">Rental Inquiry Submitted!</h3>
					<p>
						We'll reach out soon with a quote. If you have any immediate questions, please call us at: <b>917-772-6792</b>.
					</p>
					{ this.props.userId != "" ?
						<a href="/search" className="sk-button--size-lg">Browse Gear</a>
					: 
						""
					}
				</div>
				{ this.props.userId == "" ? 
					<div>
						<hr></hr>
						<h3 className="form-header">Last Step to Complete Your Account</h3>
						<p>
							Create an account now to checkout faster later!
						</p>
						<div className="sk-form__row">
							<label className="sk-form__input-wrapper">
								<span className="sk-form__input-label">
									Password*
								</span>
								{this.state.errors.userPassword && <span className="concierge-warning">{this.state.errors.userPassword}</span>}
								<input type="password" className="sk-form__text-input form-control" name="userPassword"  value={this.state.userPassword} onChange={this.handleNewUserForm} />
							</label>
						</div>
            <div className="js-email-exists error-red-box hidden">
              This email address is already tied to an account. Please <a href="/users/sign_in">log in</a> to continue.
            </div>
						<div className="form-navigation">
							<input type="submit" ref="submitButton" className="sk-button--size-lg" value="Create an account" disabled={this.state.userPassword == ""} onClick={this.validate.bind(this)}/>
						</div>
					</div>
				: 
					""
				}
			</div>
		)
	}
}

export default HomepageConciergeComplete