import React from 'react';
import PropTypes from 'prop-types';

class CourseListingsSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="cart-listing-summary-container">
				<div>
					<h3 className="item-header">1 Item</h3>
				</div>
				<div className="course-listings">
					<img src={this.props.listingObj.listing_img || "/assets/missing_listing_large.png"} className="checkout-listing-img" />
					<div>
						<p>
							<strong>{this.props.listingObj.listing_name}</strong>
						</p>
						<p className="course-session-dates">
							{this.props.listingObj.session_dates}
						</p>
						<p className="checkout-subtotal">${parseFloat(this.props.pricePerSession).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</p>
					</div>
				</div>
			</div>

		)
	}
}

export default CourseListingsSummary
