import React from 'react';
import PropTypes from 'prop-types';

class CourseConversationsPriceSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="price-summary-container">
				<div className="price-summary-table">
					<table className="table">
						<tbody>
							<tr>
								<td>
									<p className="price-estimate-p checkout-subtotal-subheading">Sub Total</p>
								</td>
								<td>
									<p className="checkout-subtotal">
									${parseFloat(this.props.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="price-estimate-p checkout-muted">Convenience Fee</p>
								</td>
								<td>
									<p className="checkout-price">
										${parseFloat(this.props.convenienceFee).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="price-estimate-p checkout-muted">Tax</p>
								</td>
								<td>
									<p className="checkout-price">
										${parseFloat(this.props.tax).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							{this.props.discountTypeApplied != "" ?
								<tr>
									<td>
										<p className="price-estimate-p">KitSplit {this.props.discountTypeApplied}</p>
									</td>
									<td>
										<p className="checkout-price checkout-discount">
											-${this.props.discountTypeApplied == "coupon" ? parseFloat(this.props.couponAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : parseFloat(this.props.creditAmountApplied).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
										</p>
									</td>
								</tr>
							: 
								""
							}
							<tr>
								<td>
									<p className="price-estimate-p checkout-subheading">Total</p>
								</td>
								<td>
									<p className="checkout-total">
										${parseFloat(this.props.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
			</div>
		)
	}
}

export default CourseConversationsPriceSummary
