import React from "react"
import PropTypes from "prop-types"
import ProjectType from "./ProjectType"
import ProjectDetails from "./ProjectDetails"
import ProjectPickup from "./ProjectPickup"
import ConciergeComplete from "./ConciergeComplete"

class ConciergeForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: this.props.user,
			step: 1,
			projectType: "feature film",
			otherProject: "",
			location: "",
			pickupDate: "",
			dropOffDate: "",
			name: "",
			email: "",
			phoneNumber: "",
			gearNeeded: [
				{item: this.props.formProduct == "" ? "" : this.props.formProduct, placeholder: "Arri Alexa Mini"},
				{item: "", placeholder: "Set of Sigma Prime Lenses(24, 35, 50, 85)"},
				{item: "", placeholder: "Small Lighting & Grip package"}
			  ],
			gearNeededList: "",
			showListView: true,
		};

		this.handleChange = this.handleChange.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.previousStep = this.previousStep.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleGearListChange = this.handleGearListChange.bind(this);
		this.addGear = this.addGear.bind(this);
        this.switchFormInputType = this.switchFormInputType.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.target.name] : event.target.value
		})
	}

	nextStep() {
		this.setState({
			step: this.state.step + 1
		})
	}

	previousStep() {
		this.setState({
			step: this.state.step - 1
		})
	}

	switchFormInputType() {
        this.setState({
            showListView: !this.state.showListView,
        })
	}
	
	addGear(event) {
		event.preventDefault();
		this.state.gearNeeded.push({item: ""})
		this.setState({gearNeeded: this.state.gearNeeded})
	}

	handleGearListChange(event) {
        const target = event.target;
        this.state.gearNeeded[target.dataset.key].item = target.value;
        this.setState({gearNeeded: this.state.gearNeeded})
    }

	handleSubmitForm(e) {
		e.preventDefault();
		var data = {
			"projectType": this.state.projectType,
			"otherProject": this.state.otherProject,
			"gearNeeded": this.state.gearNeeded,
			"location": this.state.location,
			"pickUpDate": this.state.pickupDate,
			"dropOffDate": this.state.dropOffDate,
			"name": this.state.name,
			"email": this.state.email,
			"phoneNumber": this.state.phoneNumber,
      		"userId": this.state.user
		}
		
		$.ajax({
      url: "/concierge_request",
      type: 'POST',
      dataType: 'json',
      data: data,

      success: function (response) {
      	event.preventDefault();
      	this.setState({
					step: 4,
					bidRequestId: response.bidId
				})
      }.bind(this),
      error: function (response) {

      }
    });
	}

	showStep() {
		switch (this.state.step) {
			case 1:
				return <ProjectType
								handleChange={this.handleChange}
								handleGearListChange={this.handleGearListChange}
								switchFormInputType={this.switchFormInputType}
								nextStep={this.nextStep}
								addGear={this.addGear}
								projectType={this.state.projectType}
								otherProject={this.state.otherProject}
								gearNeeded={this.state.gearNeeded}
								gearNeededList={this.state.gearNeededList}
								showListView={this.state.showListView} />
			case 2:
				return <ProjectDetails
								handleChange={this.handleChange}
								nextStep={this.nextStep}
								previousStep={this.previousStep}
								projectType={this.state.projectType}
								location={this.state.location}
								pickupDate={this.state.pickupDate}
								dropOffDate={this.state.dropOffDate} />
			case 3:
				return <ProjectPickup
								handleChange={this.handleChange}
								previousStep={this.previousStep}
								name={this.state.name}
								email={this.state.email}
								phoneNumber={this.state.phoneNumber}
								handleSubmitForm={this.handleSubmitForm}
								reCaptchaSiteKey={this.props.recaptcha_key}/>
			case 4:
				return <ConciergeComplete
								user={this.state.user}
								bidRequestId={this.state.bidRequestId} />
		}
	}

	render() {
		var circles = []
		for(var i = 1; i < 4; i++) {
			circles.push(<span className={i == this.state.step ? "step active" : "step"} key={i}></span>)
		}

		return (
			<form id="concierge-form" >
				{ this.state.step == 4 ? 
					"" 
					: 
					<div className="header-container">
						<h4 className="form-header">Rental Agents are standing by 7 days a week</h4>
						<p className="form-subheader">Call us: 917-722-6792</p>
						<p>Or send us your gear list with the easy form below. Your rental agent will follow up with you.</p>
					</div>
				}

				{this.showStep()}

				<div className="steps">
					{this.state.step == 4 ? "" : circles}
				</div>
			</form>
		);
	};
}

export default ConciergeForm
