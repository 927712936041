import React from 'react'
import PropTypes from 'prop-types'

class LeaveDeposit extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="insurance-option-details">
				{this.props.conversationRentalLength <= 5 ? 
					<div>
						<div>
							<p className="insurance-section-heading">Leave a desposit for the replacement value</p>
							<button className="sk-link-button change-coverage-link" onClick={this.props.changeInsuranceOption}>CHANGE COVERAGE TYPE</button>
						</div>
						<hr />
						<p>
							A temporary hold of <strong>${parseInt(this.props.totalReplacementValue).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</strong> will be placed on your card for the full replacement value of the item(s) that you're renting.
						</p>
						
						<p className="insurance-hold-details">
							By clicking the button below, you approve a temporary hold for the above amount to be placed on your card. The hold will appear the day before your rental begins, and will be removed when your rental ends.
						</p>
						<button className="btn sk-button--size-lg" onClick={this.props.submitFullDeposit}>I APPROVE</button>
					</div>
				: 
					<div>
						<p><b>Sorry! Your rental is too long for a deposit. We're only able to accept this option for rentals of five days or less. Please choose an alternative option.</b></p>
						<button className="sk-link-button" onClick={this.props.changeInsuranceOption}>CHANGE COVERAGE TYPE</button>
					</div>
				}
			</div>
		)
	}
}

export default LeaveDeposit