import React from 'react'
import PropTypes from 'prop-types'

import { OverlayTrigger, Tooltip } from "react-bootstrap"

class InsuranceSelectCoverage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const internationalTooltip = (
		  <Tooltip id="intl-tooltip">
		    The deductible applies each time you submit a claim and is the amount the you the policyholder are responsible for paying before the insurance carrier steps in for reimbursement. The standard International Deductible is $2,500. If you wish to lower your international deductible to $1,000 you’ll see a slight increase in your premium. If you wish to increase your international deductible to $5,000 you’ll see a credit applied to your premium. 
		  </Tooltip>
		);

		const theftFromAnyVehicleTooltip = (
			<Tooltip id="tfav-tooltip">
		    The deductible applies per claim event and is the amount that you the policyholder are responsible for paying before the insurance carrier steps in for reimbursement. The standard deductible for a theft from any vehicle is $2,500. If you wish to decrease the deductible to $1,000 you’ll see a slight increase in your premium. If you wish to increase the deductible to $5,000 you’ll see a credit applied to your premium. 
		  </Tooltip>
		);

		return (
			<div>
				<div>
					<h4>
						<strong>Please select your coverage options:</strong>
					</h4>
					<div className="insurance-coverage">
						<input name="" type="checkbox" id="insurance-intl-option" className="insurance-option-checkbox" name="internationalDeductibleSelected" checked={this.props.internationalDeductibleSelected} onChange={this.props.handleCheckbox} /> International Deductible (Optional coverage for international travel)
						<OverlayTrigger placement="right" overlay={internationalTooltip}>
							<span className="insurance-tooltip glyphicon glyphicon-question-sign"></span>
						</OverlayTrigger>
					</div>
					{this.props.internationalDeductibleSelected ?
						<form className="">
							<div className="insurance-select-form">
								<label className="sk-form__input-wrapper--select-wrapper">
									<select name="internationalDeductibleOption" className="sk-form__select" defaultValue={this.props.internationalDeductibleOption} onChange={this.props.updateIndividualInsuranceCost}>
										<option value="international_option1">
											$1,000 Deductible - Will increase base premium by 10%
										</option>
										<option value="international_option2">
											$2,500 Standard Deductible - No change to premium
										</option>
										<option value="international_option3">
											$5,000 Deductible - Will decrease base premium by 10%
										</option>
									</select>
								</label>
							</div>
						</form>
						:
						<div></div>
					}
					<div className="insurance-coverage">
						<input name="" type="checkbox" id="insurance-tfav-option" checked={this.props.theftFromAnyVehicleDeductibleSelected} name="theftFromAnyVehicleDeductibleSelected" className="insurance-option-checkbox" onChange={this.props.handleCheckbox} />
							Theft From Any Vehicle Deductible
						<OverlayTrigger placement="right" overlay={theftFromAnyVehicleTooltip}>
							<span className="insurance-tooltip glyphicon glyphicon-question-sign"></span>
						</OverlayTrigger>
					</div>
					{this.props.theftFromAnyVehicleDeductibleSelected ?
						<form className="">
							<div className="insurance-select-form">
								<label className="sk-form__input-wrapper--select-wrapper">
									<select name="theftFromAnyVehicleOption" className="sk-form__select" defaultValue={this.props.theftFromAnyVehicleOption} onChange={this.props.updateIndividualInsuranceCost}>
										<option value="TFAV_option1">
										$1,000 Deductible - Will increase base premium by 10%
										</option>
										<option value="TFAV_option2">
										$2,500 Standard Deductible - No change to premium
										</option>
										<option value="TFAV_option3">
										$5,000 Deductible - Will decrease base premium by 10%
										</option>
									</select>
								</label>
							</div>
						</form>
					:
						<div></div>
					}
					<div className="insurance-coverage">
						<input name="" type="checkbox" checked={this.props.theftFromUnlockedVehicleSelected} name="theftFromUnlockedVehicleSelected" className="insurance-option-checkbox" onChange={this.props.handleCheckbox} />
							Select add coverage for theft from an unlocked vehicle
					</div>
					<div>
						{ this.props.theftFromUnlockedVehicleSelected ?
							<p className="tfuv-warning-text"><i>We advise you to avoid leaving equipment in an unlocked vehicle, ever! However, if you absolutely must, know that the policy does <u>NOT</u> include coverage for theft from an unlocked vehicle unless you choose to add that coverage now while applying for the policy. If you wish to remove the unlocked vehicle exclusion and allow coverage for theft from an unlocked vehicle, you’ll see a slight increase to your premium. </i></p>
							:
							""
						}
					</div>
				</div>
				<div className="form-navigation">
		    	<button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
		    	<button type="button" className="sk-button--size-lg next" onClick={this.props.nextStep}>Next</button>
	    	</div>
    	</div>
		)
	}
}

export default InsuranceSelectCoverage
