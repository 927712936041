import React from 'react'
import PropTypes from 'prop-types'

class IndividualInsuranceUnderwriting extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showClaimForm: false
		}
	}

	showAddlForm(value) {
		this.setState({
			showClaimForm: value
		})
	}

	render() {
		return (
			<div>
				<p>
					Have you had a claim paid out more than $5,000 within the last year?
				</p>
				<form className="underwriting-radio-btns">
					<div className="radio">
	            <input type="radio" checked={this.state.showClaimForm === false} onChange={() => this.showAddlForm(false)} />
	            	No
	   
	        </div>
	        <div className="radio">
	       
	            <input type="radio" checked={this.state.showClaimForm === true} onChange={() => this.showAddlForm(true)} />
	            	Yes
	         
	        </div>
				</form>
				{ this.state.showClaimForm == true ?
					<div className="sk-form__wrapper">
			    	<div className="sk-form__row">
				    	<label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Insurance carrier
			          </span>
			          <input type="text" className="sk-form__text-input form-control" name="insuranceCarrier" value={this.props.insuranceCarrier} onChange={this.props.handleFormChange} />
			        </label>
			        <label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Policy Number
			          </span>
			          <input type="text" className="sk-form__text-input form-control" name="insuranceCarrier" value={this.props.insuranceCarrier} onChange={this.props.handleFormChange} />
			        </label>
		        </div>
		        <div className="sk-form__row">
			        <label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Policy start date
			          </span>
			          <input type="date" className="sk-form__text-input form-control" name="policyStartDate" value={this.props.policyStartDate} onChange={this.props.handleFormChange} />
			        </label>
			        <label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Policy end date
			          </span>
			          <input type="date" className="sk-form__text-input form-control" name="policyEndDate" value={this.props.policyEndDate} onChange={this.props.handleFormChange} />
			        </label>
			        <label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Date of loss
			          </span>
			          <input type="date" className="sk-form__text-input form-control" name="dateOfLoss" value={this.props.dateOfLoss} onChange={this.props.handleFormChange} />
			        </label>
		        </div>
		        <div className="sk-form__row">
		        	<label className="sk-form__input-wrapper--two-thirds">
			          <span className="sk-form__input-label">
			            Items included in claim
			          </span>
			          <textarea type="text" className="sk-form__textarea form-control" name="itemsInClaim" value={this.props.itemsInClaim} onChange={this.props.handleFormChange} ></textarea>
			        </label>
		        </div>
		      	<div className="sk-form__row">
		        	<label className="sk-form__input-wrapper--two-thirds">
			          <span className="sk-form__input-label">
			            Claim circumstances
			          </span>
			          <input type="text" className="sk-form__text-input form-control" name="claimCircumstances" value={this.props.claimCircumstances} onChange={this.props.handleFormChange} />
			        </label>
		        </div>
		        <div className="sk-form__row">
		        	<label className="sk-form__input-wrapper--third">
			          <span className="sk-form__input-label">
			            Total claim payout
			          </span>
			          <input type="text" className="sk-form__text-input form-control" name="totalClaimPayout" value={this.props.totalClaimPayout} onChange={this.props.handleFormChange} />
			        </label>
		        </div>
					</div>
					:
					<div>
					</div>
				}
				<div className="form-navigation">
		    	<button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
		    	<button type="button" className="sk-button--size-lg next" onClick={this.props.nextStep}>Next</button>
	    	</div>
			</div>
		)
	}
}

export default IndividualInsuranceUnderwriting
