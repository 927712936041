import React from 'react';
import PropTypes from 'prop-types';

class PriceSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="price-summary-container">
				<div className="price-summary-table">
					<table className="table">
						<tbody>
							<tr>
								<td>
									<p className="price-estimate-p checkout-subtotal-subheading">Sub Total</p>
								</td>
								<td>
									<p className="checkout-subtotal">
										${parseFloat(this.props.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="price-estimate-p checkout-muted">Convenience Fee</p>
								</td>
								<td>
									<p className="checkout-price">
										${parseFloat(this.props.convenienceFee).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="price-estimate-p checkout-muted">Tax</p>
								</td>
								<td>
									<p className="checkout-price">
										${parseFloat(this.props.tax).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="price-estimate-p">{this.props.insuranceOption == "Leave Full Deposit" ?  "Hold on credit card" : "Coverage Estimate"}</p>
								</td>
								<td>
									<p className="checkout-price">
										{"$" + parseFloat(this.props.insuranceAmount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
							{ this.props.discountTypeApplied != "" ?
								<tr>
									<td>
										<p className="price-estimate-p">KitSplit {this.props.discountTypeApplied}</p>
									</td>
									<td>
										<p className="checkout-price checkout-discount">
											-${this.props.discountTypeApplied == "coupon" ? parseFloat(this.props.couponAmt).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : parseFloat(this.props.referralCredit).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
										</p>
									</td>
								</tr>
								: <tr></tr>
							}
							{ this.props.ownerDiscount > 0 ?
								<tr>
									<td>
										<p className="price-estimate-p">Owner Discount</p>
									</td>
									<td>
										<p className="checkout-price checkout-discount">
											-${parseFloat(this.props.ownerDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
										</p>
									</td>
								</tr>
							: ""
							}
							<tr>
								<td>
									<p className="price-estimate-p checkout-subheading">Total</p>
								</td>
								<td>
									<p className="checkout-total">
										${parseFloat(this.props.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}
									</p>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
				<p className="checkout-replacement-value">
					Total Replacement Value: ${parseFloat(this.props.totalReplacementValue).toLocaleString()}
				</p>
			</div>
		)
	}
}

export default PriceSummary
