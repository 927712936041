import React from 'react';
import PropTypes from 'prop-types';
import PurchaseInsurance from './PurchaseInsurance';
import ProvideCoi from './ProvideCoi';
import LeaveDeposit from './LeaveDeposit';

import { OverlayTrigger, Tooltip } from "react-bootstrap"

class CheckoutInsurance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			insuranceOptionSelected: false,
			// rdday cleanup
			// insuranceOption: 0
		}
		this.changeInsuranceOption = this.changeInsuranceOption.bind(this);
	}

	selectInsurance(option) {
		this.setState({
			insuranceOptionSelected: true,
			// rdday cleanup
			// insuranceOption: option
		})
		this.props.handleStateChange({
			previewInsuranceOption: option
		})
		// rdday cleanup
		// if(option == "1") {
		// 	this.props.setInitialIndividualInsuranceCost();
		// }
	}

	changeInsuranceOption() {
		this.setState({
			insuranceOptionSelected: false,
			// insuranceOption: 0
		});
		this.props.handleStateChange({
			previewInsuranceOption: 0
		})
	}

	isCOIValidOption() {
    // if it's FRIDAY:
    //   and I am checking out:
    //     before 2pm: rental can start today with a start time after 12 noon
    //     after 2pm: rental must start following monday with a start time after 12 noon

    // if it's SATURDAY:
    //   and I am checking out:
    //     between 12:01am and 11:59pm: rental must start following Monday with a start time after 12 noon

    // if it's SUNDAY:
    //   and I am checking out:
    //     between 12:01am and 11:59pm: rental must start following Monday with a start time after 12 noon

    // if it's MONDAY, TUES, WED, THURS:
    //   and I am checking out:
    //     before 10am: rental must start after 12 noon (today)
    //     between 10am - 4pm: rental can start anytime
    //     after 4pm: rental must start next day after 12 noon

    if(this.props.userCoiOverride == true) {
      return true
    }

		let today = new Date()
		let eighteenHoursFromToday = moment(today).add(18, 'hours')
    let todaysDateTime = moment(today)
    let start_of_rental = new Date(this.props.startDateTime)
		let rentalStartDate = moment(start_of_rental)

    let dayOfWeek = today.getDay()
    
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();

    var todayAt10 = moment(new Date(year, month, day, 10,0,0));
    var todayAtNoon = moment(new Date(year, month, day, 12,0,0));
    var todayAt2 = moment(new Date(year, month, day, 14,0,0));
    var todayAt4 = moment(new Date(year, month, day, 16,0,0));
    var tomorrowAtNoon = moment(new Date(year, month, day+1, 12,0,0));

    var mondayAtNoon;
    if(dayOfWeek == 0) {
      mondayAtNoon = moment(new Date(year, month, day+1, 12,0,0));
    } else if(dayOfWeek == 6) {
      mondayAtNoon = moment(new Date(year, month, day+2, 12,0,0));    
    } else if(dayOfWeek == 1) {
      mondayAtNoon = moment(new Date(year, month, day, 12,0,0));
    } else if(dayOfWeek == 2) {
      mondayAtNoon = moment(new Date(year, month, day+6, 12,0,0));
    } else if(dayOfWeek == 3) {
      mondayAtNoon = moment(new Date(year, month, day+5, 12,0,0));
    } else if(dayOfWeek == 4) {
      mondayAtNoon = moment(new Date(year, month, day+4, 12,0,0));
    } else if(dayOfWeek == 5) {
      mondayAtNoon = moment(new Date(year, month, day+3, 12,0,0));
    }

    // logic to return true/false if coi is allowable:
    if(dayOfWeek == 0 || dayOfWeek == 6) {
      // Saturday, Sunday
      if(rentalStartDate > mondayAtNoon) {
        return true
      } else {
        return false
      }
    } else if(dayOfWeek == 1 || dayOfWeek == 2 || dayOfWeek == 3 || dayOfWeek == 4) {
      // Monday, Tues, Wed, Thurs

      if(todaysDateTime <= todayAt10 ) {
        if(rentalStartDate > todayAtNoon) {
          return true
        } else {
          return false
        }
      } else if(todaysDateTime > todayAt10 && todaysDateTime < todayAt4) {
        return true
      } else if(todaysDateTime >= todayAt4) {
        if(rentalStartDate > tomorrowAtNoon) {
          return true
        } else {
          return false
        }
      }      
    } else if(dayOfWeek == 5) {
      // Friday
      if(todaysDateTime <= todayAt2 ) {
        if(rentalStartDate > todayAtNoon) {
          return true
        } else {
          return false
        }
      } else if(todaysDateTime > todayAt2) {
        if(rentalStartDate > mondayAtNoon) {
          return true
        } else {
          return false
        }
      }
    }
	}

	showInsuranceOption() {
		switch (this.props.previewInsuranceOption) {
			case 0:
				return <div></div>
			case 1:
				return <PurchaseInsurance
								changeInsuranceOption={this.changeInsuranceOption}
								totalReplacementValue={this.props.totalReplacementValue}
								insuranceCoverageCeiling={this.props.insuranceCoverageCeiling}
								individualRentalPremium={this.props.individualRentalPremium}
								damageWaiverEstimate={this.props.damageWaiverEstimate}
								submitIndividualDamageWaiverForm={this.props.submitIndividualDamageWaiverForm}
								submitIndividualInsuranceForm={this.props.submitIndividualInsuranceForm}
								handleFormChange={this.props.handleFormChange}
								handleCheckbox={this.props.handleCheckbox}
								agreeToTerms={this.props.agreeToTerms}
								accountTypeForm={this.props.accountTypeForm}
								setInternationalInsuranceOption={this.props.setInternationalInsuranceOption}
								setTheftFromAnyVehicleOption={this.props.setTheftFromAnyVehicleOption}
								firstNameForm={this.props.firstNameForm}
								lastNameForm={this.props.lastNameForm}
								address1Form={this.props.address1Form}
								address2Form={this.props.address2Form}
								zipCodeForm={this.props.zipCodeForm}
								cityForm={this.props.cityForm}
								stateForm={this.props.stateForm}
								phoneNumberForm={this.props.phoneNumberForm}
								birthdayForm={this.props.birthdayForm}
								insuranceCarrier={this.props.insuranceCarrier}
								policyStartDate={this.props.policyStartDate}
								policyEndDate={this.props.policyEndDate}
								policyNumber={this.props.policyNumber}
								dateOfLoss={this.props.dateOfLoss}
								itemsInClaim={this.props.itemsInClaim}
								claimCircumstances={this.props.claimCircumstances}
								totalClaimPayout={this.props.totalClaimPayout}
								internationalDeductibleSelected={this.props.internationalDeductibleSelected}
								internationalDeductibleOption={this.props.internationalDeductibleOption}
								theftFromAnyVehicleDeductibleSelected={this.props.theftFromAnyVehicleDeductibleSelected}
								theftFromAnyVehicleOption={this.props.theftFromAnyVehicleOption}
								theftFromUnlockedVehicleSelected={this.props.theftFromUnlockedVehicleSelected}
								updateIndividualInsuranceCost={this.props.updateIndividualInsuranceCost}
								existingPolicyId={this.props.existingPolicyId}
								existingPolicyReplacementValue={this.props.existingPolicyReplacementValue}
							/>
			case 2:
				return <ProvideCoi
								changeInsuranceOption={this.changeInsuranceOption}
								individualRentalPremium={this.props.individualRentalPremium}
								submitProductionInsuranceForm={this.props.submitProductionInsuranceForm}
								/>
			case 3:
				return <LeaveDeposit
								changeInsuranceOption={this.changeInsuranceOption}
								totalReplacementValue={this.props.totalReplacementValue}
								conversationRentalLength={this.props.conversationRentalLength}
								submitFullDeposit={this.props.submitFullDeposit}
								/>
		}
	}

	render() {
		const tooltip = (
		  <Tooltip id="tooltip">
		    {this.props.conversationRentalLength > 5 ? "Option not available because rental length is more than 5 days." : ""}
		  </Tooltip>
		);

		const individualInsuranceTooltip = (
			<Tooltip id="individual-insurance-tooltip">
		    	Option is not available for total gear value greater than $250,000.
		  	</Tooltip>
		);

		const COIInsuranceTooltip = (
			<Tooltip id="coi-insurance-tooltip">
		    	Option is currently not available.
		  	</Tooltip>
		);

		const excludeDroneCoverageTooltip = (
			<Tooltip id="coi-insurance-tooltip">
		    	For drone coverage, please provide your own insurance or leave a full deposit.
		  	</Tooltip>
		);
		
		return (
			<div className="checkout-insurance-container">
				<h3 className="insurance-header">{this.props.totalReplacementValue > 10000 ? "Instant Insurance Options" : "Instant Damage Coverage Options"}</h3>
				{this.state.insuranceOptionSelected == false ?
					<div>
						<p>
							All KitSplit rentals must be covered for the full replacement value of the gear.
						</p>
						<div className="insurance-options-container">
							{ (parseFloat(this.props.totalReplacementValue) > 250000 || this.props.cartHasDrone) ?
								<OverlayTrigger placement="bottom" overlay={this.props.totalReplacementValue > 250000 ? individualInsuranceTooltip : excludeDroneCoverageTooltip}>
									<button className="insurance-button insurance-button disabled-insurance" disabled="true" onClick={() => this.selectInsurance(1)}>
										<div className="insurance-option">
											<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-1.png" className={this.props.totalReplacementValue > 10000 ? "insurance-icon-individual" : "insurance-icon"} />
											<div className="insurance-btn-text">
												<p><strong>{this.props.totalReplacementValue > 10000 ? "Purchase Short-Term Rental Insurance" : "Purchase Short-Term Damage Coverage" }</strong></p>
												<p className="insurance-estimated-cost">Estimated Cost: ${ this.props.totalReplacementValue > 10000 ? parseFloat(this.props.individualRentalPremium).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : parseFloat(this.props.damageWaiverEstimate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }</p>
											</div>
										</div>
									</button>
								</OverlayTrigger>

							:
								<button className="insurance-button" onClick={() => this.selectInsurance(1)}>
									<div className="insurance-option">
										<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-1.png" className={this.props.totalReplacementValue > 10000 ? "insurance-icon-individual" : "insurance-icon"} />
										<div className="insurance-btn-text">
											<p><strong>{this.props.totalReplacementValue > 10000 ? (this.props.existingPolicyId == "" ? "Purchase Short-Term Insurance" : "Add Onto Your Existing Short-Term Policy") : "Purchase Short-Term Damage Coverage" }</strong></p>
											<p className="insurance-estimated-cost">Estimated Cost: ${ this.props.totalReplacementValue > 10000 ? (this.props.existingPolicyId == "" ? parseFloat(this.props.individualRentalPremium).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : parseFloat(this.props.policyExtensionCost).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')) : parseFloat(this.props.damageWaiverEstimate).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }</p>
										</div>
									</div>
								</button>
							}
							{ this.isCOIValidOption() ? 
								<button className="insurance-button" onClick={() => this.selectInsurance(2)}>
									<div className="insurance-option">
										<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-2.png" className="insurance-icon" />
										<div className="insurance-btn-text">
											<p><strong>Provide Your Own Insurance Policy</strong></p>
											<p className="insurance-estimated-cost">No additional cost</p>
										</div>
									</div>
								</button>
								:
								<OverlayTrigger placement="bottom" overlay={COIInsuranceTooltip}>
									<button className="insurance-button disabled-insurance" disabled="true" onClick={() => this.selectInsurance(2)}>
										<div className="insurance-option">
											<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-2.png" className="insurance-icon" />
											<div className="insurance-btn-text">
												<p><strong>Provide Your Own Insurance Policy</strong></p>
												<p className="insurance-estimated-cost">No additional cost</p>
											</div>
										</div>
									</button>
								</OverlayTrigger>
							}
							{ this.props.conversationRentalLength > 5 ?
								<OverlayTrigger placement="bottom" overlay={tooltip}>
									<button className={this.props.conversationRentalLength > 5 ? 'insurance-button disabled-insurance' : 'insurance-button'} data-toggle="tooltip" data-placement="top" title={this.props.conversationRentalLength > 5 ? "Option not available because rental length is more than 5 days." : ""} disabled={this.props.conversationRentalLength > 5} onClick={() => this.selectInsurance(3)}>
										<div className="insurance-option">
											<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-3.png" className="insurance-icon3" />
											<div className="insurance-btn-text">
												<p><strong>Leave a Deposit for the Replacement Value</strong></p>
												<p className="insurance-estimated-cost">Deposit amount: ${parseFloat(this.props.totalReplacementValue).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</p>
											</div>
										</div>
									</button>
								</OverlayTrigger>
							:
								<button className={this.props.conversationRentalLength > 5 ? 'insurance-button disabled-insurance' : 'insurance-button'} data-toggle="tooltip" data-placement="top" title={this.props.conversationRentalLength > 5 ? "Option not available because rental length is more than 5 days." : ""} disabled={this.props.conversationRentalLength > 5} onClick={() => this.selectInsurance(3)}>
									<div className="insurance-option">
										<img src="https://s3.amazonaws.com/kitsplit/img/insurance-icon-3.png" className="insurance-icon" />
										<div className="insurance-btn-text">
											<p><strong>Leave a Deposit for the Replacement Value</strong></p>
											<p className="insurance-estimated-cost">Deposit amount: ${parseFloat(this.props.totalReplacementValue).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</p>
										</div>
									</div>
								</button>
							}
						</div>
					</div> : <div></div>
				}
				{this.showInsuranceOption()}

				<p>
					Need help? Read more about our insurance options, and checkout out our <a href="https://kitsplit.zendesk.com/hc/en-us/sections/360007816291-Insurance-and-Damage-Waiver" target="_blank" className="sk-link-button">insurance FAQ</a> or contact our team.
				</p>
			</div>
		)
	}
}

export default CheckoutInsurance
