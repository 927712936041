import React from "react";
import PropTypes from "prop-types";

class RatingStars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: this.props.rating
    }
  }

  renderStars() {
    if (this.props.rating > 4.75) {
      return (
        <div id="stars">
          <span className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>
        </div>
      )
    } else {
      let stars = [];
      let rating = parseInt(this.props.rating);
      const isValidRating = !isNaN(rating);

      if (isValidRating) {
        for (var i = 0; i < rating; i++) {
          stars.push(<span key={i} className="glyphicon glyphicon-star rating-stars" aria-hidden="true"></span>)
        }

        if (rating % 1 !== 0 && typeof rating !== 'undefined') 
          stars.push(<span key={i} className="glyphicon glyphicon-star glyphicon-half rating-stars" aria-hidden="true"></span>);
      } else {
        stars.push(<p className="muted-text">No reviews yet.</p>)
      }

      return stars
    }
  }

  render() {
    return (
      <div className='rating-stars'>
        <span className="dont-wrap" data-toggle="tooltip" data-placement="top" title={(Math.round(this.props.rating * 100) / 100).toString()}>
        { this.renderStars() }
        </span>
      </div>
    )
  }

}

export default RatingStars
