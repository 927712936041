import React from "react"
import PropTypes from "prop-types"
import yup from "yup";
import Recaptcha from "react-recaptcha";

class ConciergeStep5 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			recaptchaChecked: false
		};
	};

	allowSubmission() {
		this.setState({recaptchaChecked: true});
	}

	render() {
		const context = this;
		return (
			<div>
				<div>
					<Recaptcha
						sitekey={this.props.reCaptchaSiteKey}
						render="explicit"
						verifyCallback={this.allowSubmission.bind(this)}
					/>
				</div>
                <div className="form-navigation">
                    <button type="button" className="sk-link-button previous" onClick={this.props.previousStep}>&lt; Back</button>
                    <input type="button" ref="submitButton"className="sk-button--size-lg" value="Submit" disabled={!this.state.recaptchaChecked} onClick={this.props.handleSubmitForm}/>
                </div>
            </div>
		)
	}
};

export default ConciergeStep5
