import React from "react"
import PropTypes from "prop-types"
import ListingRequest from "./ListingRequest"

class SingleMultipleRentalRequest extends React.Component {
  constructor(props) {
    super(props);

    this.submitListingRequests = this.submitListingRequests.bind(this);
  }

  submitListingRequests(e) {
    e.preventDefault();

    $(this.refs.errorMessage).addClass('hidden');

    const context = this;
    const userMessage = $(this.refs.requestMessage).val();
    const $listings   = $('input#request_listing:checked').parents('.listing-request');
    if ($listings.length > 0) {
      const listingsIds = _.map($listings, function(listing) {;
        return $(listing).data('id');
      });

      // Mimic event
      const event = {
        target: this.refs.skipButton,
        preventDefault: function ( ){}
      }

      $.ajax({
        url: Routes.process_multiple_rental_requests_path({format: 'json'}),
        type: 'PATCH',
        dataType: 'json',
        data: {
          message: userMessage,
          listing_ids: listingsIds,
          start_date: new Date(this.props.start_date),
          end_date: new Date(this.props.end_date),
          shoot: getUrlParameter('shoot')
        },
        success: function(res) {
          // Trigger onWindowSkip UI event
          const indexInfo = context.props.skipThisWindow(event, context);

           // Last slide of ListingRequests
          if (indexInfo.total_count === indexInfo.current_index){
            let url = Routes.checkout_success_path() + '?shoot=' + res.shoot_id;

            if (getUrlParameter('unconfirmed') == 'true')
              url += '&unconfirmed=true';

            window.location.href = url;
          }
        }
      });
    } else {
      $(this.refs.errorMessage).removeClass('hidden');
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      listings_data: nextProps.listings_data,
      triggerListingInfoModal: nextProps.triggerListingInfoModal,
      triggerListingMapModal: nextProps.triggerListingMapModal
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.listings_data !== this.props.listings_data) {
      return true;
    }
    return false;
  }

  renderListingRequests(full=false) {
    let listings = this.props.listings_data;
    let listingRequests = [];

    let listing_count = listings.length;
    if(full === false) {
      if(listings.length >= 3) {
        listing_count = 3;
      }
    }

    for (var i = 0; i < listing_count; i++) {
      let listingData = listings[i];
      listingData.key = listingData.listing_id;
      listingData.price_per_day = numberToCurrency(listingData.price_per_day);
      listingData.modalTrigger = this.props.triggerListingInfoModal;
      listingData.mapTrigger = this.props.triggerListingMapModal;

      let request = <ListingRequest {...listingData}/>
      listingRequests.push(request)
    }

    return listingRequests;
  }

  render() {
    const messagePlaceholder = "Hey! I'd like to rent your " + this.props.listings_data[0].product_name + " starting on " + (new Date(this.props.start_date)).toDateString()  + ', ' + this.props.start_time + ' and returning on ' + (new Date(this.props.end_date)).toDateString() + ', ' + this.props.end_time +  '.';

    return (
      <div className="request-card">
        <div className="make-sure-you-get-one row">
          <h3>
            Request the  <span id="listing-name">{this.props.listings_data[0].product_name}</span> from other owners to ensure you find available gear
          </h3>
          <p>
            We recommend messaging at least one more owner to increase your chances of finding available gear to rent.
          </p>
        </div>

        <ul className="targeted-listings targeted-listings-short">
          {this.renderListingRequests(false)}
        </ul>

        <ul className="targeted-listings targeted-listings-full hidden">
          {this.renderListingRequests(true)}
        </ul>

        <a href="#" className="js-show-addl-listings sk-link-button" onClick={showFullListings}></a>
        <a href="#" className="js-hide-addl-listings hidden sk-link-button" onClick={hideFullListings}></a>

        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">


            <form className="sk-form__wrapper">
              <div className="sk-form__row">
                <label className="sk-form__input-wrapper">
                  <span className="sk-form__input-label"> Your Message </span>
                  <textarea ref="requestMessage" placeholder="Send a descriptive message to gear owners" className="sk-form__textarea" defaultValue={messagePlaceholder} style={{padding: '15px'}}></textarea>
                </label>
              </div>

              <div className="sk-modal__actions" style={{}}>
                <div className="hidden error pull-left" ref="errorMessage">
                  You must select at least one listing to send a request.
                </div>
                <a href="#" ref="skipButton" className="sk-link-button" onClick={ (e) => this.props.skipThisWindow(e, this) }>
                  Skip
                </a>
                <a href="#" className="sk-button" onClick={this.submitListingRequests}>Send</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SingleMultipleRentalRequest;
